<template>
  <div>
    <a-row type="flex" justify="end">
      <a-button type="primary" class="editable-add-btn" @click="showModal">
        {{$t('addevent')}}
      </a-button>
    </a-row>
    <a-modal v-model="visible" :title="$t('newevent')" :width="1150" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          {{$t('cancel')}}
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="addEvent">
          {{$t('save')}}
        </a-button>
      </template>
      <div>
        <eventcard v-bind:event="newEvent" v-bind:newEvent="true"/>
      </div>
    </a-modal>
    <div v-for="item in data" :key="item._id">
      <div style="margin-bottom: 2%">
        <eventcard v-bind:event="item" v-bind:getData="getData" v-bind:newEvent="false"></eventcard>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import eventcard from './eventCard.vue'
import moment from 'moment'

export default {
  data () {
    return {
      data: {},
      newEvent: {
        isactive: false,
        isdeleted: false,
        name: '',
        img: '',
        available_time: {
          monday: { open: null, close: null, isopen: false },
          tuesday: { open: null, close: null, isopen: false },
          wednesday: { open: null, close: null, isopen: false },
          thursday: { open: null, close: null, isopen: false },
          friday: { open: null, close: null, isopen: false },
          saturday: { open: null, close: null, isopen: false },
          sunday: { open: null, close: null, isopen: false }
        },
        isdaterange: false,
        start_date: null,
        end_date: null
      },
      loading: false,
      visible: false
    }
  },
  components: {
    eventcard
  },
  created () {
    moment.locale('th')
    this.getData()
  },
  methods: {
    showModal () {
      this.visible = true
    },
    handleCancel (e) {
      this.visible = false
    },
    async getData () {
      try {
        const { data } = await axios.get('/1.0/agent/events')
        data.events = data.events.map(event => {
          event.start_date = event.start_date ? moment(event.start_date) : null
          event.end_date = event.end_date ? moment(event.end_date) : null
          if (event.available_time.monday.open.includes('.')) {
            const times = event.available_time.monday.open.split('.')
            event.available_time.monday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.monday.open = moment().hour(event.available_time.monday.open).minute(0)
          if (event.available_time.monday.close.includes('.')) {
            const times = event.available_time.monday.close.split('.')
            event.available_time.monday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.monday.close = moment().hour(event.available_time.monday.close).minute(0)
          if (event.available_time.tuesday.open.includes('.')) {
            const times = event.available_time.tuesday.open.split('.')
            event.available_time.tuesday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.tuesday.open = moment().hour(event.available_time.tuesday.open).minute(0)
          if (event.available_time.tuesday.close.includes('.')) {
            const times = event.available_time.tuesday.close.split('.')
            event.available_time.tuesday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.tuesday.close = moment().hour(event.available_time.tuesday.close).minute(0)
          if (event.available_time.wednesday.open.includes('.')) {
            const times = event.available_time.wednesday.open.split('.')
            event.available_time.wednesday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.wednesday.open = moment().hour(event.available_time.wednesday.open).minute(0)
          if (event.available_time.wednesday.close.includes('.')) {
            const times = event.available_time.wednesday.close.split('.')
            event.available_time.wednesday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.wednesday.close = moment().hour(event.available_time.wednesday.close).minute(0)
          if (event.available_time.thursday.open.includes('.')) {
            const times = event.available_time.thursday.open.split('.')
            event.available_time.thursday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.thursday.open = moment().hour(event.available_time.thursday.open).minute(0)
          if (event.available_time.thursday.close.includes('.')) {
            const times = event.available_time.thursday.close.split('.')
            event.available_time.thursday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.thursday.close = moment().hour(event.available_time.thursday.close).minute(0)
          if (event.available_time.friday.open.includes('.')) {
            const times = event.available_time.friday.open.split('.')
            event.available_time.friday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.friday.open = moment().hour(event.available_time.friday.open).minute(0)
          if (event.available_time.friday.close.includes('.')) {
            const times = event.available_time.friday.close.split('.')
            event.available_time.friday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.friday.close = moment().hour(event.available_time.friday.close).minute(0)
          if (event.available_time.saturday.open.includes('.')) {
            const times = event.available_time.saturday.open.split('.')
            event.available_time.saturday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.saturday.open = moment().hour(event.available_time.saturday.open).minute(0)
          if (event.available_time.saturday.close.includes('.')) {
            const times = event.available_time.saturday.close.split('.')
            event.available_time.saturday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.saturday.close = moment().hour(event.available_time.saturday.close).minute(0)
          if (event.available_time.sunday.open.includes('.')) {
            const times = event.available_time.sunday.open.split('.')
            event.available_time.sunday.open = moment().hour(times[0]).minute(times[1])
          } else event.available_time.sunday.open = moment().hour(event.available_time.sunday.open).minute(0)
          if (event.available_time.sunday.close.includes('.')) {
            const times = event.available_time.sunday.close.split('.')
            event.available_time.sunday.close = moment().hour(times[0]).minute(times[1])
          } else event.available_time.sunday.close = moment().hour(event.available_time.sunday.close).minute(0)
          return event
        })
        this.data = data.events
      } catch (error) {

      }
    },
    async addEvent () {
      this.loading = true
      const bodyFormData = new FormData()
      // Open
      const mondayOpen = this.newEvent.available_time.monday.open ? parseFloat(moment(this.newEvent.available_time.monday.open).format('LT').replace(':', '.')) : 0
      const tuesdayOpen = this.newEvent.available_time.tuesday.open ? parseFloat(moment(this.newEvent.available_time.tuesday.open).format('LT').replace(':', '.')) : 0
      const wednesdayOpen = this.newEvent.available_time.wednesday.open ? parseFloat(moment(this.newEvent.available_time.wednesday.open).format('LT').replace(':', '.')) : 0
      const thursdayOpen = this.newEvent.available_time.thursday.open ? parseFloat(moment(this.newEvent.available_time.thursday.open).format('LT').replace(':', '.')) : 0
      const fridayOpen = this.newEvent.available_time.friday.open ? parseFloat(moment(this.newEvent.available_time.friday.open).format('LT').replace(':', '.')) : 0
      const saturdayOpen = this.newEvent.available_time.saturday.open ? parseFloat(moment(this.newEvent.available_time.saturday.open).format('LT').replace(':', '.')) : 0
      const sundayOpen = this.newEvent.available_time.sunday.open ? parseFloat(moment(this.newEvent.available_time.sunday.open).format('LT').replace(':', '.')) : 0
      // Close
      const mondayClose = this.newEvent.available_time.monday.close ? parseFloat(moment(this.newEvent.available_time.monday.close).format('LT').replace(':', '.')) : 0
      const tuesdayClose = this.newEvent.available_time.tuesday.close ? parseFloat(moment(this.newEvent.available_time.tuesday.close).format('LT').replace(':', '.')) : 0
      const wednesdayClose = this.newEvent.available_time.wednesday.close ? parseFloat(moment(this.newEvent.available_time.wednesday.close).format('LT').replace(':', '.')) : 0
      const thursdayClose = this.newEvent.available_time.thursday.close ? parseFloat(moment(this.newEvent.available_time.thursday.close).format('LT').replace(':', '.')) : 0
      const fridayClose = this.newEvent.available_time.friday.close ? parseFloat(moment(this.newEvent.available_time.friday.close).format('LT').replace(':', '.')) : 0
      const saturdayClose = this.newEvent.available_time.saturday.close ? parseFloat(moment(this.newEvent.available_time.saturday.close).format('LT').replace(':', '.')) : 0
      const sundayClose = this.newEvent.available_time.sunday.close ? parseFloat(moment(this.newEvent.available_time.sunday.close).format('LT').replace(':', '.')) : 0
      let availabletime = {
        monday: { open: mondayOpen, close: mondayClose, isopen: this.newEvent.available_time.monday.isopen },
        tuesday: { open: tuesdayOpen, close: tuesdayClose, isopen: this.newEvent.available_time.tuesday.isopen },
        wednesday: { open: wednesdayOpen, close: wednesdayClose, isopen: this.newEvent.available_time.wednesday.isopen },
        thursday: { open: thursdayOpen, close: thursdayClose, isopen: this.newEvent.available_time.thursday.isopen },
        friday: { open: fridayOpen, close: fridayClose, isopen: this.newEvent.available_time.friday.isopen },
        saturday: { open: saturdayOpen, close: saturdayClose, isopen: this.newEvent.available_time.saturday.isopen },
        sunday: { open: sundayOpen, close: sundayClose, isopen: this.newEvent.available_time.sunday.isopen }
      }
      availabletime = JSON.stringify(availabletime)
      bodyFormData.append('isactive', this.newEvent.isactive)
      bodyFormData.append('isdeleted', this.newEvent.isdeleted)
      bodyFormData.append('name', this.newEvent.name)
      bodyFormData.append('img', this.newEvent.img)
      bodyFormData.append('available_time', availabletime)
      bodyFormData.append('isdaterange', this.newEvent.isdaterange)
      bodyFormData.append('start_date', this.newEvent.start_date ? this.newEvent.start_date : null)
      bodyFormData.append('end_date', this.newEvent.end_date ? this.newEvent.end_date : null)

      axios.post('/1.0/agent/event', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        this.loading = false
        this.visible = false
        this.$success({
          title: 'Create Event Success',
          content: (
            <div>
              <p>create event success</p>
            </div>
          )
        })
        this.getData()
      }).catch(() => {
        this.loading = false
        this.$error({
          title: 'Can not add event',
          content: 'event not found'
        })
      })
    }
  }
}
</script>
<style>
.editable-add-btn {
  margin-bottom: 8px;
}
.edit-button{
  padding-right: 5%;
}
</style>
