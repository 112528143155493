<template>
  <div class="login">
    <div class="centered">
      <a-card style="width: 300px">
        <div class="space-align-container">
          <div class="space-align-block">
            <a-space align="baseline">
              <img
                src="@/assets/Logo_Area.webp"
                alt="logo"
                width="220"
              />
            </a-space>
          </div>
        </div>

        <a-form layout="horizontal" :form="form" @submit="handleSubmit">
          <a-form-item
            :validate-status="userNameError() ? 'error' : ''"
            :help="userNameError() || ''"
          >
          <div class="flex components-input-size justify-center">
          <img
                class="absolute"
                src="@/assets/Area_Username.webp"
                alt="logo"
                width="220"
              />
              <a-input
              v-decorator="[
                'userName',
                {
                  rules: [
                    { required: true, message: 'Please input your username!' },
                  ],
                },
              ]"
              placeholder="Username"
            >
              <!-- <span slot="prefix" :style="{ fontSize: '16px' }">
                <i class="anticon anticon-lock">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M17.754 14a2.25 2.25 0 0 1 2.247 2.148v.102h-7.997v.999l7.975.001a3.751 3.751 0 0 1-.509 1.501l-7.466-.002v1l6.638.002c-1.56 1.506-3.79 2.25-6.642 2.25c-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.434v-.578a2.249 2.249 0 0 1 2.249-2.25zM12 2a4.99 4.99 0 0 1 4 2h-3.997v.999L16.584 5c.204.468.339.972.392 1.5h-4.973v1l4.973.002a4.97 4.97 0 0 1-.392 1.5L12.003 9v1l3.997.001A5 5 0 1 1 12 2"/></svg>
                </i>
              </span> -->
            </a-input>
        </div>

          </a-form-item>

          <a-form-item
            :validate-status="passwordError() ? 'error' : ''"
            :help="passwordError() || ''"
          >
          <div class="flex components-input-size justify-center">
            <img
                class="absolute"
                src="@/assets/Area_Password.webp"
                alt="logo"
                width="220"
              />
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: 'Please input your Password!' },
                  ],
                },
              ]"
              type="password"
              placeholder="Password"
            >
              <!-- <span slot="prefix" :style="{ fontSize: '16px' }">
                <i class="anticon anticon-lock">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm6-5q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z"
                    />
                  </svg>
                </i>
              </span> -->
            </a-input>
          </div>
          </a-form-item>
          <a-form-item>
            <a-button
              html-type="submit"
              :disabled="hasErrors(form.getFieldsError())"
            >
            <img style="width: 100%; height: auto;" src="../assets/Login_Area.webp" alt="buttonpng" border="0" />
              <!-- Log in -->
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
      <a-modal
        v-model="otpmodal"
        title="Add Google Authenticator"
        ok-text="Confirm"
        cancel-text="Cancel"
        @ok="confirmotp"
      >
        <a-row type="flex" justify="center">
          <p><b>Set up Authenticator</b></p>
        </a-row>
        <a-row type="flex" justify="center">
          <p>
            ดาวโหลด Authenticator App จาก <b>Play Store</b> หรือ
            <b>App Store</b>
          </p>
        </a-row>
        <a-row type="flex" justify="center">
          <p>เปิด Authenticator App เลือก <b>Set-up Account</b></p>
        </a-row>
        <a-row type="flex" justify="center">
          <p>เลือก <b>Scan a Barcode</b></p>
        </a-row>
        <a-row type="flex" justify="center">
          <img :src="otpdata" alt="otpqr" />
        </a-row>
        <a-row type="flex" justify="center">
          กรุณากรอกรหัสที่ได้จากการ Scan Barcode
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :sm="24" :md="12">
            <a-input
              class="text-center"
              v-model="otpcode"
              placeholder="xxx xxx"
            />
          </a-col>
        </a-row>
      </a-modal>
      <a-modal
        v-model="sendotp"
        title="Authenticator App Code"
        ok-text="Confirm"
        cancel-text="Cancel"
        @ok="loginotp"
      >
        <a-row type="flex" justify="center">
          <p><b>กรุณากรอกรหัสที่ได้จาก Authenticator App *(xxx xxx)</b></p>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :sm="24" :md="12">
            <a-input
              class="text-center"
              v-model="otpcode"
              placeholder="xxx xxx"
            />
          </a-col>
        </a-row>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { setIsSub, setAuthToken } from '../utils/auth'
// setAuthToken
import axios from 'axios'
import { sync } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field])
}
export default {
  data () {
    return {
      hasErrors,
      otpmodal: false,
      sendotp: false,
      firstToken: '',
      token: '',
      otpdata: null,
      otpcode: '',
      userdata: {
        username: '',
        password: ''
      },
      form: this.$form.createForm(this, { name: 'horizontal_login' })
    }
  },
  mounted () {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.setFieldsValue({ userName: '', password: '' })
      this.form.validateFields()
    })
  },
  computed: {
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  methods: {
    async loginotp () {
      await axios
        .post('/1.0/agent/login', {
          userToken: this.otpcode,
          username: this.userdata.username,
          password: this.userdata.password
        })
        .then((res) => {
          setIsSub(res.data.issup)
          setAuthToken(res.data.token)
          if (res.data.ispasswordneedtochange) {
            this.$router.push('/force/password')
          } else {
            this.$router.push('/')
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.otpcode = ''
            this.$message.error(err.response.data.message)
          }
        })
      this.sendotp = false
    },
    async confirmotp () {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + this.firstToken
          }
        }
        const { data } = await axios.put(
          '/1.0/agent/authenticator',
          {
            userToken: this.otpcode
          },
          config
        )
        if (!data.isvalid) {
          this.$notification.error({
            message: 'รหัสยืนยันไม่ถูกต้อง',
            description:
              'กรุณากรอกรหัส 6 หลักที่ได้ใน Authenticator App *(ตัวอย่าง xxx xxx)'
          })
          this.otpcode = ''
        } else {
          this.$notification.success({
            message: 'Update รหัส Authenticator สำเร็จ',
            description: 'กรุณา Log in อีกครั้ง'
          })
          this.otpmodal = false
          this.otpcode = ''
          window.location.reload()
        }
      } catch (error) {
        console.log(error)
      }
    },
    // Only show error after a field is touched.
    userNameError () {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('userName') && getFieldError('userName')
    },
    // Only show error after a field is touched.
    passwordError () {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('password') && getFieldError('password')
    },
    async handleSubmit (e) {
      // try {
      e.preventDefault()
      await this.form.validateFields(async (err, values) => {
        try {
          if (!err) {
            // await loginUser(values.userName, values.password).then((successCallback, failureCallback) => {
            //   console.log(successCallback)
            //   console.log(failureCallback)
            // })
            const { data } = await axios.post('/1.0/agent/authenticator', {
              username: values.userName,
              password: values.password
            })
            this.userdata.username = values.userName
            this.userdata.password = values.password
            if (!data.isotp) {
              // console.log('token', data.token)
              this.firstToken = data.token
              console.log(this.firstToken)
              // setAuthToken(data.token)
              setIsSub(data.issup)
              this.otpdata = data.data_url
              this.otpmodal = true
            } else {
              this.sendotp = true
            }
            // await axios.post('/1.0/agent/login', {
            //   username: values.userName,
            //   password: values.password
            // }).then((res) => {
            //   setIsSub(res.data.issup)
            //   setAuthToken(res.data.token)
            //   if (res.data.ispasswordneedtochange) {
            //     this.$router.push('/force/password')
            //   } else {
            //     this.$router.push('/')
            //   }
            // }).catch((err) => {
            //   if (err.response.status === 401) {
            //     this.$message.error(err.response.data.message)
            //   }
            // })
            // await getProfile()
            // this.$router.push('/')
          }
        } catch (error) {
          // console.log('error ===>', error)
          this.$notification.error({
            message: 'Login ผิดพลาด',
            description:
              'ท่านพิมพ์รหัสผ่านไม่ถูกต้องหากต้องการ Reset Password กรุณาติดต่อ Admin'
            // onClick: () => {
            //   console.log('Notification Clicked!')
            // }
          })
        }
      })
      // } catch (error) {
      //   console.log('error', error)
      // }
    }
  }
}
</script>
<style>
body {
  /* height: 100%; */

  /*Monitors Large than 1920px, image has "soft" edges to blend into background */
  /* @media (min-width:1367px) and (max-width:1920px){
    background: url("../assets/BG_Chinese-06.webp") no-repeat center center fixed !important;
    background-size:100% auto;
  } */
/* Mointores ranging from 769px - 1366px */
  @media (min-width:769px) and (max-width:1366px){
    background: url("../assets/BG_Area_lg.webp") no-repeat center top fixed #190303;
    background-size:100% auto;
  }
/* Mointores ranging from 769px - 1366px */
  @media (max-width:768px){
    background: url("../assets/BG_Area_sm.webp") no-repeat center top fixed #190303;
    background-size:100% auto;
  }
  /* background: rgb(235, 234, 234) !important; */
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  background: transparent !important;
  transform: translate(-50%, -50%);
}
.components-input-size .ant-input {
  width: 160px;
  margin: 0 8px 8px 0;
  left: 12px;
  top: 16px;
}
</style>
<style scoped>
.ant-input:hover {
  border-color: #f67d0c !important;
  /*
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  */
}
.ant-btn {
  /* border-color: #f67d0c !important; */
  /* background: url('../assets/LOGIN_Area.png') !important;
  background-size: cover; */
  /* background: #f67d0c !important; */
  /* color: black !important; */
  /* box-shadow: 0px 15px 20px rgba(4, 4, 4, 0.4) !important; */
  background-color: inherit!important;
  transition: transform 0.7s ease-in-out!important;
  border: none!important;
  cursor: pointer!important;
}
.ant-btn:hover {
  /* border-color: #f67d0c !important; */
  /* color: rgb(213, 208, 208) !important; */
  /* box-shadow: 0px 15px 20px rgba(4, 4, 4, 0.4) !important; */
  /* color: #fff !important; */
  transform: translateY(-7px) !important;
}
.ant-card-bordered {
  border: 0 !important;
}
.ant-card {
  background: transparent !important;
}
.custom-icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
.space-align-container {
  display: flex;
  align-item: flex-start;
  flex-wrap: wrap;
}
.space-align-block {
  margin: 8px 4px;
  /* border: 1px solid #40a9ff; */
  padding: 4px;
  flex: none;
}
.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  /* background: rgba(150, 150, 150, 0.2); */
}
</style>
