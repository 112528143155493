<template>
  <div>
    <div class="flex flex-row items-center w-1/3 py-2 space-x-2">
      <span>Username</span>
      <a-input placeholder="username" v-model="username" />
      <!-- <span>{{$t('type')}}</span>
      <a-select
        default-value=""
        style="width: 100%"
        @change="handleMemberTypeChange"
      >
        <a-select-option value="">
          {{$t('all')}}
        </a-select-option>
        <a-select-option value="agent">
          {{$t('agent')}}
        </a-select-option>
        <a-select-option value="user">
          {{$t('member')}}
        </a-select-option>
      </a-select> -->

      <a-button type="primary" @click="searchName">
        {{$t('search')}}
      </a-button>
    </div>
    <div>
      <a-radio-group default-value="agent" button-style="solid" @change="onChange">
        <a-radio-button value="agent">
          {{$t('agent')}}
        </a-radio-button>
        <a-radio-button value="user">
          {{$t('member')}}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div style="padding-top: 1%">
      <a-breadcrumb>
        <a-breadcrumb-item xstyle="color: blue" v-for="(scope, index) in scopes" :key="index"><a @click="callscope(scope)">{{scope}}</a></a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-table
      :columns="columns"
      :data-source="myDownline"
      :loading="loadingData"
      rowKey="_id" size="small"
      :scroll="{ x: 1000 }"
      :pagination="{ defaultPageSize: itemsPerPage, total: totaluser, onChange: pageChange }"
      :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
    >
      <a v-if="record.type === 'agent'" slot="username" slot-scope="text, record" @click="() => member(record._id, record.username)">{{ text }}</a>
      <a v-else slot="username" slot-scope="text">{{ text }}</a>
      <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ parseFloat(text).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</a></a>
    </a-table>
  </div>
</template>

<script>

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    scopedSlots: { customRender: 'username' }
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '15%'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: '15%'
  },
  {
    title: 'Wallettype',
    dataIndex: 'wallettype',
    width: '10%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10%'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    width: '20%',
    scopedSlots: { customRender: 'balance' }
  }
]

export default {
  data () {
    return {
      columns,
      selectType: 'agent',
      username: '',
      agent_id: '',
      myDownline: [],
      loadingData: false,
      totaluser: 0,
      page: 1,
      itemsPerPage: 50,
      scopes: [],
      scopesId: []
    }
  },
  created () {
    this.agent_id = this.$route.query.agent_id
    this.scopes = this.$route.query.scope
    this.scopesId = [this.$route.query.agent_id]
    this.getData()
  },
  methods: {
    callscope (username) {
      const scopeindex = this.scopes.findIndex(scope => scope === username)
      this.scopes = this.scopes.slice(0, scopeindex + 1)
      this.agent_id = this.scopesId[scopeindex - 1]
      this.getData()
      if (scopeindex === 0) {
        this.$router.push('/member/memberagent')
      }
    },
    async getData () {
      try {
        this.loadingData = true
        const response = await this.axios.get('/1.0/agent/submydownline', { params: { agent_id: this.agent_id, username: this.username, type: this.selectType, limit: this.itemsPerPage, skip: (this.page - 1) * this.itemsPerPage } })
        if (response.status === 200) {
          this.loadingData = false
          this.totaluser = response.data.total
          this.myDownline = response.data.users.map(data => data)
        }
      } catch (error) {
      }
    },
    pageChange (page) {
      this.page = page
      this.getData()
    },
    // async handleMemberTypeChange (value) {
    //   this.selectType = value
    //   this.getData()
    // },
    async onChange (e) {
      this.selectType = e.target.value
      await this.getData()
    },
    async searchName () {
      this.getData()
    },
    member (val, username) {
      this.agent_id = val
      this.scopes.push(username)
      this.scopesId.push(val)
      this.getData()
    }
  }
}
</script>

<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
</style>
