<template>
  <div>
    <div class="search-username">
      <div style="padding-right: 1%;">
        <a-input placeholder="username" v-model="username" />
      </div>
      <div>
        <a-button type="primary" @click="searchName">
          {{$t('search')}}
        </a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="agent"
      rowKey="_id"
      size="small"
      :scroll="{ x: 600 }"
      :loading="loadingData"
      :pagination="{ defaultPageSize: itemsPerPage, total: totaluser, onChange: pageChange }"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" @click="editcommission(record._id)">
          <a-icon type="edit" />
        </a-button>
      </span>
    </a-table>
    <a-modal
      title="Commissions"
      :visible="visible"
      :width="800"
      :footer="null"
      @cancel="handleCancel"
    >
      <template>
        <a-table :columns="columnsCommissions" :data-source="commissions" bordered :scroll="{ x: 600 }" :pagination="{ defaultPageSize: 100 }">
          <template
            v-for="col in ['rate']"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-select v-if="record.editable" :default-value="select_list" style="width: 100%" @change="value => handleChange(value)">
                <template v-for="(list, index) in record.listpercent">
                  <a-select-option :key="index" :value="list.value">
                    {{ list.label }}
                  </a-select-option>
                </template>
              </a-select>
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record.key)">{{$t('save')}}</a>
                <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.key)">
                  <a>{{$t('cancel')}}</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a :disabled="editingKey !== ''" @click="() => edit(record.key)">{{$t('edit')}}</a>
              </span>
            </div>
          </template>
        </a-table>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { sync } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Wallettype',
    dataIndex: 'wallettype'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'Commissions',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: '17%'
  }
]

const columnsCommissions = [
  {
    title: 'Games',
    dataIndex: 'games',
    width: '35%',
    scopedSlots: { customRender: 'games' }
  },
  {
    title: 'All',
    dataIndex: 'parent_rate',
    width: '10%',
    scopedSlots: { customRender: 'parent_rate' }
  },
  {
    title: 'You',
    dataIndex: 'diffrate',
    width: '10%',
    scopedSlots: { customRender: 'diffrate' }
  },
  {
    title: 'Agent',
    dataIndex: 'rate',
    scopedSlots: { customRender: 'rate' }
  },
  {
    title: 'Edit Commissions',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: '20%'
  }
]

const commissions = []
export default {
  data () {
    return {
      username: '',
      columns,
      agent: [],
      visible: false,
      confirmLoading: false,
      agentID: '',
      columnsCommissions,
      commissions,
      editingKey: '',
      select_list: 0,
      hasErrors,
      loadingData: false,
      totaluser: 0,
      page: 1,
      itemsPerPage: 50
    }
  },
  computed: {
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  created () {
    this.getAgent()
  },
  methods: {
    async getAgent (username) {
      this.loadingData = true
      try {
        const response = await this.axios.get('/1.0/agent/mydownlineall', { params: { username: username, type: 'agent', limit: this.itemsPerPage, skip: (this.page - 1) * this.itemsPerPage } })
        if (response.status === 200) {
          this.loadingData = false
          this.totaluser = response.data.total
          this.agent = response.data.users
        }
      } catch (error) {
      }
    },
    async searchName () {
      await this.getAgent(this.username)
    },
    pageChange (page) {
      this.page = page
      this.getAgent()
    },
    async fetchCommission () {
      try {
        const response = await this.axios.get('/1.0/agent/commission', { params: { agent_id: this.agentID } })
        if (response.status === 200) {
          const conditions = ['PRAGMATICC', 'IRONDOG', 'RELAX', 'NOLIMITCITY', 'BLUEPRINT', 'YGGDRASIL', 'IRONDOG', 'BOOONGO', '1X2GAMING', 'ELK', 'BETTER', 'RTG', 'REVOLVER', 'KIRON', 'RELOADED', 'PUSH', 'EZUGI', 'FANTASMA', 'QUICKSPIN', 'WAZDAN']
          const qt = response.data.commissions.find(commission => commission.code === 'qt')
          qt.productId = 'QTECH'
          response.data.commissions = response.data.commissions.filter(product => conditions.every(c => c !== product.productId))
          this.commissions = response.data.commissions.map((commission) => {
            const list = []
            for (let i = commission.parent_rate; i >= 0; i--) {
              list.push({
                label: `${i}%`,
                value: i
              })
            }
            return {
              key: commission.code,
              games: `${commission.productId} [${commission.type}]`,
              parent_rate: `${commission.parent_rate}%`,
              diffrate: `${commission.diffrate}%`,
              rate: `${commission.rate}%`,
              listpercent: list
            }
          })
          this.cacheData = this.commissions.map(item => ({ ...item }))
        }
      } catch (error) {
      }
    },
    async editcommission (id) {
      this.visible = true
      this.agentID = id
      try {
        const response = await this.axios.get('/1.0/agent/commission', { params: { agent_id: id } })
        if (response.status === 200) {
          const conditions = ['PRAGMATICC', 'IRONDOG', 'RELAX', 'NOLIMITCITY', 'BLUEPRINT', 'YGGDRASIL', 'IRONDOG', 'BOOONGO', '1X2GAMING', 'ELK', 'BETTER', 'RTG', 'REVOLVER', 'KIRON', 'RELOADED', 'PUSH', 'EZUGI', 'FANTASMA', 'QUICKSPIN', 'WAZDAN']
          const qt = response.data.commissions.find(commission => commission.code === 'qt')
          qt.productId = 'QTECH'
          response.data.commissions = response.data.commissions.filter(product => conditions.every(c => c !== product.productId))
          // this.plainOptions = response.data.products.map((items) => (items.productId))
          // this.plainOptions.push('QTECH')
          this.commissions = response.data.commissions.map((commission) => {
            const list = []
            for (let i = commission.parent_rate; i >= 0; i--) {
              list.push({
                label: `${i}%`,
                value: i
              })
            }
            return {
              key: commission.code,
              games: `${commission.productId} [${commission.type}]`,
              parent_rate: `${commission.parent_rate}%`,
              diffrate: `${commission.diffrate}%`,
              rate: `${commission.rate}%`,
              listpercent: list
            }
          })
          this.cacheData = this.commissions.map(item => ({ ...item }))
        }
      } catch (error) {
      }
    },
    handleCancel (e) {
      this.visible = false
    },
    handleChange (value) {
      this.select_list = value
    },
    edit (key) {
      const newData = [...this.commissions]
      const target = newData.filter(item => key === item.key)[0]
      this.editingKey = key
      if (target) {
        target.editable = true
        this.select_list = target.rate
        this.commissions = newData
      }
    },
    async save (key) {
      try {
        const value = {
          code: key,
          rate: this.select_list
        }
        const response = await this.axios.put(
          '/1.0/agent/commission?agent_id=' + this.agentID,
          value,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        if (response.status === 200) {
          this.messageApi = response.data.message
          this.hasError = false
          await this.fetchCommission()
        }
      } catch (error) {
        this.messageApi = error.response.data.message
        this.hasError = true
      }
      if (this.hasError) {
        this.$message.error(this.messageApi)
      } else {
        this.$message.success(this.messageApi)
      }
      this.editingKey = ''
    },
    cancel (key) {
      const newData = [...this.commissions]
      const target = newData.filter(item => key === item.key)[0]
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.filter(item => key === item.key)[0])
        delete target.editable
        this.commissions = newData
      }
    }
  }
}
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.ant-table td { white-space: nowrap; }
.search-username {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1%;
}
</style>
