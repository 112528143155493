<template>
  <div>
    <template v-if="product_list.length === 0">
      <a-empty />
    </template>
    <a-checkbox-group v-else @change="onChange" :value="select_product" style="width: 100%;">
      <a-row :gutter="[32,16]">
        <template v-for="(product, index) in product_list">
          <a-col :span="12" :key="index" >
            <a-checkbox :value="product.value">
              {{product.label}}
            </a-checkbox>
          </a-col>
        </template>
      </a-row>
    </a-checkbox-group>
    <div style="text-align: center; padding-top: 5%;">
      <a-button type="primary" @click="addProduct">
        {{$t('save')}}
      </a-button>
    </div>
  </div>
</template>
<script>
import { sync } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

export default {
  data () {
    return {
      product_list: [],
      select_product: [],
      hasErrors
    }
  },
  computed: {
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  async created () {
    await this.myProducts()
    await this.getProduct()
  },
  methods: {
    async getProduct () {
      try {
        const response = await this.axios.get('/1.0/agent/products')
        if (response.status === 200) {
          this.product_list = response.data.products.map((items) =>
            ({
              label: items.name,
              value: items.productId
            })
          )
        }
      } catch (error) {
      }
    },
    async myProducts () {
      try {
        const response = await this.axios.get('/1.0/agent/mygames')
        if (response.status === 200) {
          this.select_product = response.data.products.productId
        }
      } catch (error) {
      }
    },
    onChange (checkedValues) {
      this.select_product = checkedValues
    },
    async addProduct () {
      try {
        const value = {
          product: this.select_product
        }
        const response = await this.axios.post(
          '/1.0/agent/mygames',
          value,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        if (response.status === 200) {
          this.messageApi = response.data.message
          this.hasError = false
        }
      } catch (error) {
        this.messageApi = error.response.data.message
        this.hasError = true
      }
      if (this.hasError) {
        this.$message.error(this.messageApi)
      } else {
        this.$message.success(this.messageApi)
      }
    }
  }
}
</script>
