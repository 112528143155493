<template>
  <div>
    <a-form :form="form">
      <a-row type="flex" justify="space-around">
        <a-col :xs="24" :xl="8">
          <span style="font-weight: bold;">{{ $t('basicinfo') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('username')"
            >
              <a-input
                disabled
                v-model="profile.username"
                placeholder="Please input your name"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('phonenumber')"
            >
              <a-input
                :placeholder="$t('phonenumber')"
                v-decorator="[
                  'phone',
                  {
                    rules: [
                      {
                        message: 'Phone Number required'
                      },
                      {
                        pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: 'Please enter a valid Phone Number'
                      }
                    ]
                  }
                ]"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('nickname')"
            >
              <a-input
                :placeholder="$t('nickname')"
                v-decorator="['nickname']"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="Website URL"
            >
              <a-input
                placeholder="Website URL"
                v-decorator="['website']"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="IP Whitelist"
            >
              <template v-for="(tag, index) in tags">
                <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                  <a-tag color="blue" :key="tag" :closable="index !== -1" @close="() => handleClose(tag)">
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag color="blue" v-else :key="tag" :closable="index !== -1" @close="() => handleClose(tag)">
                  {{ tag }}
                </a-tag>
              </template>
              <a-input
                v-if="inputVisible"
                ref="input"
                type="text"
                size="small"
                :style="{ width: '150px' }"
                :value="ipwhitelist"
                @change="handleInputChange"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
              />
              <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                <a-icon type="plus" /> New IP
              </a-tag>
            </a-form-item>
          </div>
        </a-col>
        <a-col :xs="24" :xl="8">
          <span style="font-weight: bold;">{{ $t('credit') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('currency')"
            >
              <a-input
                disabled
                :placeholder="$t('currency')"
                v-model="profile.currency"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('credit')"
            >
              <a-input
                disabled
                v-model="profile.balance"
                :placeholder="$t('credit')"
              />
            </a-form-item>
            <template v-for="commission in commissions">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :key="commission.productId"
                :label="commission.productId"
              >
              <a-input
                disabled
                v-model="commission.parent_rate"
                :key="commission.productId"
              />
            </a-form-item>
            </template>
            <!-- <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('pt')"
            >
              <a-input
                disabled
                v-model="profile.commission.max"
                :placeholder="$t('pt')"
              />
            </a-form-item> -->
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <div>
            <a-form-item
              :label-col="formTailLayout.labelCol"
              :wrapper-col="formTailLayout.wrapperCol"
            >
              <a-button type="primary" @click="handleSubmit">
                {{ $t('updateinfo') }}
              </a-button>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import axios from 'axios'
import { sync, call } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 }
}
const formTailLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 3, offset: 10 }
}
export default {
  data () {
    return {
      checkNick: false,
      formItemLayout,
      formTailLayout,
      hasErrors,
      tags: [],
      inputVisible: false,
      ipwhitelist: '',
      commissions: []
    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'profile' })
  },
  computed: {
    profile: sync('account/profile')
  },
  async created () {
    await this.fetchProfile()
    await this.getCommission()
    if (this.profile != null) {
      this.form.setFieldsValue({
        nickname: (await this.profile.nickname) || '',
        phone: (await this.profile.phone) || '',
        website: (await this.profile.website) || ''
      })
      this.tags = this.profile.ipwhitelist || []
    }
  },
  methods: {
    fetchProfile: call('account/myProfile'),
    handleClose (removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag)
      this.tags = tags
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },
    handleInputChange (e) {
      this.ipwhitelist = e.target.value
    },
    async getCommission () {
      try {
        const { data } = await axios.get('/1.0/agent/addcommission')
        this.commissions = data.commissions
      } catch (error) {
        console.log(error)
      }
    },
    handleInputConfirm () {
      const ipwhitelist = this.ipwhitelist
      let tags = this.tags
      if (ipwhitelist && tags.indexOf(ipwhitelist) === -1) {
        tags = [...tags, ipwhitelist]
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        ipwhitelist: ''
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            values.ipwhitelist = this.tags
            const response = await axios.put(
              '/1.0/agent/myprofile',
              values,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            if (response.status === 200) {
              this.messageApi = response.data.message
              this.hasError = false
              this.fetchProfile()
            }
          } catch (error) {
            this.messageApi = error.response.data.message
            this.hasError = true
          }
          if (this.hasError) {
            this.$message.error(this.messageApi)
          } else {
            this.$message.success(this.messageApi)
          }
        }
      })
    }
  }
}
</script>
