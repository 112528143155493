import { make } from 'vuex-pathify'
import axios from 'axios'

// properties
const state = {
  myDownline: [],
  message: '',
  canCreateAgent: false,
  canCreateUser: false,
  hasError: true
}

const actions = {
  async checkUsernameAgent ({ getters, commit }, params) {
    try {
      const response = await axios.get('/1.0/agent/checkusername', {
        params: params
      })
      if (response.status === 200) {
        commit('SET_CHECK_AGENT_USERNAME', true)
        commit('SET_MESSAGE', response.data.message)
      }
    } catch (error) {
      commit('SET_CHECK_AGENT_USERNAME', false)
      commit('SET_MESSAGE', error.response.data.message)
    }
  },

  async checkUsernameMember ({ getters, commit }, params) {
    try {
      const response = await axios.get('/1.0/user/checkusername', {
        params: params
      })
      if (response.status === 200) {
        commit('SET_CHECK_USER_USERNAME', true)
        commit('SET_MESSAGE', response.data.message)
      }
    } catch (error) {
      commit('SET_CHECK_USER_USERNAME', false)
      commit('SET_MESSAGE', error.response.data.message)
    }
  },

  async createUsernameAgent ({ commit }, params) {
    try {
      const response = await axios.post('/1.0/agent', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.status === 200) {
        commit('SET_MESSAGE', response.data.message)
        commit('UPDATE_HAS_ERROR', false)
      }
    } catch (error) {
      commit('UPDATE_HAS_ERROR', true)
      commit('SET_MESSAGE', error.response.data.message)
    }
  },

  async createUsernameMember ({ commit }, params) {
    try {
      const response = await axios.post(
        '/1.0/user',
        {
          username: params.username,
          password: params.password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.status === 200) {
        commit('SET_MESSAGE', response.data.message)
        commit('UPDATE_HAS_ERROR', false)
      }
    } catch (error) {
      commit('UPDATE_HAS_ERROR', true)
      commit('SET_MESSAGE', error.response.data.message)
    }
  },

  async fetchMyDownline ({ getters }, params) {
    try {
      const { data } = await axios.get('/1.0/agent/mydownline', {
        params: params
      })
      state.myDownline = data
    } catch (error) {
    }
  }
}

const mutations = {
  ...make.mutations(state),
  SET_MESSAGE (state, value) {
    state.message = value
  },
  SET_CHECK_AGENT_USERNAME (state, value) {
    state.canCreateAgent = value
  },
  SET_CHECK_USER_USERNAME (state, value) {
    state.canCreateUser = value
  },
  UPDATE_HAS_ERROR (state, value) {
    state.hasError = value
  }
}

const getters = {
  myDownline (state) {
    return state.myDownline
  },
  getCanCreateAgent (state) {
    return state.canCreateAgent
  },
  getCanCreateUser (state) {
    return state.canCreateUser
  }
}

// store
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
