<template>
  <div>
    <a-form :form="form">
      <a-row>
        <a-col :sm="24" :md="12">
          <span style="font-weight: bold;">{{ $t('basicinfo') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="Username"
            >
              <a-input-search
                :xaddon-before="profile.username"
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true
                      }
                    ]
                  }
                ]"
                placeholder="username"
                size="default"
                @search="onSearch"
              >
                <a-button
                  type="primary"
                  slot="enterButton"
                  :disabled="hasErrors(form.getFieldsError(['username']))"
                >
                  <a-icon
                    type="search"
                    :style="{ fontSize: '20px', marginTop: '5px' }"
                  />
                </a-button>
              </a-input-search>
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('password')"
            >
              <a-input-password
                :disabled="checkprefix"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true
                      },
                      {
                        validator: validateToNextPassword
                      }
                    ]
                  }
                ]"
                type="password"
                :placeholder="$t('password')"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('confirmpassword')"
            >
              <a-input-password
                :disabled="checkprefix"
                v-decorator="[
                  'confirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Confirm Password is required'
                      },
                      {
                        validator: compareToFirstPassword
                      }
                    ]
                  }
                ]"
                type="password"
                @blur="handleConfirmBlur"
                :placeholder="$t('confirmpassword')"
              />
            </a-form-item>
          </div>
          <span style="font-weight: bold;">{{ $t('creditsettings') }}</span>
          <br />
          <div style="margin-top: 20px;">
            <template v-if="profile._id === '6110d1ff47caf985cce5e470' || profile._id === '61ba2bb0f9b3631902088a0e' || profile._id === '61b9da42cfca2db54176aa21'">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('agenttype')"
              >
                <a-radio-group
                  a-radio-group
                  :disabled="checkprefix"
                  v-model="agentApiType"
                  @change="changeApi"
                >
                  <a-radio-button :value="true" >
                    API
                  </a-radio-button>
                  <a-radio-button :value="false" >
                    NON API
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </template>
            <template v-if="!agentApiType">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('wallettype')"
              >
                <a-radio-group
                  :disabled="checkprefix"
                  a-radio-group
                >
                  <a-radio-button checked>
                    Credit Line
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </template>
            <template v-else>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('wallettype')"
              >
                <a-radio-group
                  :disabled="checkprefix"
                  a-radio-group
                  v-model="wallettype"
                  @change="changewallettype"
                >
                  <a-radio-button value="seamless" disabled>
                    Seamless
                  </a-radio-button>
                  <a-radio-button value="transfer">
                    Transfer
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </template>
            <!-- <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="Callback URL"
            >
              <a-input
                :disabled="checkprefix"
                v-decorator="[
                  'callback',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Callback URL is required'
                      }
                    ]
                  }
                ]"
                placeholder="Callback URL"
              />
            </a-form-item> -->
            <!-- <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('ourpt')"
            >
              <a-input disabled :placeholder="`${myComission} %`" />
            </a-form-item> -->
            <!-- <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('givept')"
            > -->
              <!-- <a-select
                :disabled="checkprefix"
                size="default"
                style="width: 200px"
                @change="Changegivept"
                v-decorator="[
                  'commission',
                  {
                    rules: [
                      {
                        required: true
                      }
                    ]
                  }
                ]"
              >
                <a-select-option
                  v-for="i in profile.commission.max || []"
                  :key="i"
                >
                  {{ i }}
                </a-select-option>
              </a-select> -->
              <!-- <a-button
                type="primary"
                @click="addCommission"
                :disabled="false"
              >
                {{ $t('agentcommission') }}
              </a-button> -->
            <!-- </a-form-item> -->
          </div>
        </a-col>
        <a-col :sm="24" :md="12">
          <div style="margin-top: 40px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('nickname')"
            >
              <a-input
                :disabled="checkprefix"
                :placeholder="$t('nickname')"
                v-decorator="['nickname']"
              />
            </a-form-item>

            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('phonenumber')"
            >
              <a-input
                :disabled="checkprefix"
                :placeholder="$t('phonenumber')"
                v-decorator="['phone']"
              />
            </a-form-item>
          </div>
          <span style="font-weight: bold;">{{ $t('etcsetting') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="Website URL"
            >
              <a-input
                :disabled="checkprefix"
                v-decorator="['website']"
                placeholder="Website URL"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="IP Whitelist"
            >
              <template v-for="(tag, index) in tags">
                <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                  <a-tag color="blue" :key="tag" :closable="index !== -1" @close="() => handleClose(tag)">
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag color="blue" v-else :key="tag" :closable="index !== -1" @close="() => handleClose(tag)">
                  {{ tag }}
                </a-tag>
              </template>
              <a-input
                v-if="inputVisible"
                ref="input"
                type="text"
                size="small"
                :style="{ width: '150px' }"
                :value="ipwhitelist"
                @change="handleInputChange"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
              />
              <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput" >
                <a-icon type="plus" /> New IP
              </a-tag>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" align="top">
        <a-col :lg="18" :xs="24">
          <span>{{$t('givept')}}: </span>
          <a-select :disabled="checkprefix" default-value="" style="width: 120px" @change="copychange">
            <a-select-option v-for="myagent in myagents" :key="myagent._id" :value="myagent._id">
              {{myagent.username}}
            </a-select-option>
        </a-select>
        <template>
          <a-table
            bordered
            :columns="columnsCommissions"
            :data-source="commissions"
            :scroll="{ x: 600 }"
            size="small"
          >
            <template
              v-for="col in ['rate']"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-select
                  :default-value="agentcomission[record.key] !== undefined ? agentcomission[record.key] : 0"
                  style="width: 100%"
                  v-model="agentcomission[record.key]"
                  :disabled="checkprefix"
                >
                  <template v-for="(list) in record.listpercent">
                    <a-select-option :key="list.key" :value="list.value">
                      {{ list.label }}
                    </a-select-option>
                  </template>
                </a-select>
              </div>
            </template>
          </a-table>
        </template>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="top">
        <a-col>
          <div>
            <a-form-item>
              <a-button
                type="primary"
                 @click="handleSubmit"
                :disabled="hasErrors(form.getFieldsError())"
              >
                {{ $t('addagent') }}
              </a-button>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formItemLayout, formTailLayout } from '../../utils/form_size'
import { get, call, sync } from 'vuex-pathify'
function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const agentApiType = true
const columnsCommissions = [
  {
    title: 'Games',
    dataIndex: 'games',
    scopedSlots: { customRender: 'games' }
  },
  {
    title: 'You',
    dataIndex: 'parent_rate',
    width: '15%',
    scopedSlots: { customRender: 'parent_rate' }
  },
  {
    title: 'Agent',
    dataIndex: 'rate',
    scopedSlots: { customRender: 'rate' }
  }
]

const commissions = []
export default {
  data () {
    return {
      hasErrors,
      checkprefix: true,
      agentApiType,
      wallettype: 'transfer',
      checkNick: false,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      giveptMac: 0,
      myComission: 0,
      tags: [],
      inputVisible: false,
      ipwhitelist: '',
      columnsCommissions,
      commissions,
      select_list: 0,
      agentcomission: {},
      myagents: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields()
    })
    this.giveptMac = this.profile.commission.max
    this.myComission = this.profile.commission.max
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'addagent' })
  },
  computed: {
    profile: get('account/profile'),
    canCreate: get('member/canCreateAgent'),
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  async created () {
    const { data } = await this.axios.get('/1.0/agent/mydownlineall', {
      params: {
        limit: 1000,
        skip: 0,
        type: 'agent'
      }
    })
    this.myagents = data.users
    if (this.profile._id === '6110d1ff47caf985cce5e470' || this.profile._id === '61ba2bb0f9b3631902088a0e' || this.profile._id === '61b9da42cfca2db54176aa21') {
      this.agentApiType = true
    } else {
      this.agentApiType = false
    }
    this.addCommission()
  },
  methods: {
    checkUsernameAgent: call('member/checkUsernameAgent'),
    createUsernameAgent: call('member/createUsernameAgent'),
    copychange (ag) {
      const agent = this.myagents.find(a => a._id === ag)
      for (const com of this.commissions) {
        this.agentcomission[com.key] = agent.commission[com.key]
      }
      this.$forceUpdate()
    },
    handleClose (removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag)
      this.tags = tags
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },
    handleInputChange (e) {
      this.ipwhitelist = e.target.value
    },
    handleInputConfirm () {
      const ipwhitelist = this.ipwhitelist
      let tags = this.tags
      if (ipwhitelist && tags.indexOf(ipwhitelist) === -1) {
        tags = [...tags, ipwhitelist]
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        ipwhitelist: ''
      })
    },
    Changegivept (value) {
      this.myComission = this.profile.commission.max - value
    },
    async onSearch (value) {
      if (value !== null) {
        const _value = value
        await this.checkUsernameAgent({ username: _value })
        this.checkprefix = !this.canCreate
        if (this.canCreate) {
          this.$message.success(this.messageApi)
        } else {
          this.$message.error(this.messageApi)
        }
      }
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('Two passwords that you enter is inconsistent!')
      } else {
        callback()
      }
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const _username = values.username
          await this.createUsernameAgent({
            ...values,
            username: _username,
            isapi: this.agentApiType,
            ipwhitelist: this.tags,
            wallettype: this.wallettype,
            commission: this.agentcomission
          })
          if (this.hasError) {
            this.$message.error(this.messageApi)
          } else {
            this.$message.success(this.messageApi)
            this.form.resetFields()
            this.$router.push('/member/memberagent')
          }
          e.preventDefault()
        }
      })
    },
    handleagentApiType (e) {
      this.agentApiType = e.target.value
    },
    handlebetType (e) {
      this.wallettype = e.target.value
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    changeApi (e) {
      this.agentApiType = e.target.value
    },
    changewallettype (e) {
      this.wallettype = e.target.value
    },
    async addCommission () {
      try {
        const response = await this.axios.get('/1.0/agent/addcommission')
        if (response.status === 200) {
          const conditions = ['PRAGMATICC', 'IRONDOG', 'RELAX', 'NOLIMITCITY', 'BLUEPRINT', 'YGGDRASIL', 'IRONDOG', 'BOOONGO', '1X2GAMING', 'ELK', 'BETTER', 'RTG', 'REVOLVER', 'KIRON', 'RELOADED', 'PUSH', 'EZUGI', 'FANTASMA', 'QUICKSPIN', 'WAZDAN']
          const qt = response.data.commissions.find(commission => commission.code === 'qt')
          qt.productId = 'QTECH'
          response.data.commissions = response.data.commissions.filter(product => conditions.every(c => c !== product.productId))
          // this.plainOptions = response.data.products.map((items) => (items.productId))
          // this.plainOptions.push('QTECH')
          this.commissions = response.data.commissions.map((commission) => {
            const list = []
            for (let i = commission.parent_rate; i >= 0; i--) {
              list.push({
                label: `${i}%`,
                key: commission.code,
                value: i
              })
            }
            return {
              key: commission.code,
              games: `${commission.productId} [${commission.type}]`,
              parent_rate: `${commission.parent_rate}%`,
              // diffrate: `${commission.diffrate}%`,
              // rate: `${commission.rate}%`,
              listpercent: list
            }
          })
          // this.cacheData = this.commissions.map(item => ({ ...item }))
        }
      } catch (error) {
      }
    },
    handleChange (value, key) {
      this.agentcomission[key.key] = value
    }
  }
}
</script>
<style>
 .text-credit-line {
   background-color: limegreen;
    text-align: center;
    color: white;
    font-weight: bold;
    border-radius: 10px;
 }
</style>
