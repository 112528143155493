<template>
  <div>
    <a-card :hoverable="event.isactive" style="width: 100%;">
      <div class="display-card">
        <div class="display-card-image">
          <div style="width: 100%; padding-bottom: 5%;">
            <img
              v-if="event.img"
              style="width: 100%; border-radius: 10px;"
              alt="imgEvent"
              :src="showImage"
            />
            <template v-else>
              <a-empty />
            </template>
          </div>
          <div style="align-self: center;">
            <a-upload :show-upload-list="false" :before-upload="beforeUpload">
              <a-button :disabled="!editable_form && !newEvent"> <a-icon type="upload" /> Select File Image </a-button>
            </a-upload>
          </div>
        </div>
        <div class="display-card-detail">
          <div style="padding-bottom: 5%;">
            <a-input
              v-if="editable_form || newEvent"
              placeholder="Name Event"
              :value="event.name"
              @change="e => handleChange(e.target.value)"
            />
            <template v-else>
              {{ event.name ? event.name:('Empty') }}
            </template>
          </div>
          <div style="padding-bottom: 5%;">
            <a-checkbox style="padding-right: 5%;" id="isdaterange" :checked="event.isdaterange" :disabled="!editable_form && !newEvent" @change="event.isdaterange = !event.isdaterange"/>
            <label>{{$t('openclose')}}</label>
          </div>
          <div>
            <a-range-picker
              style="width: 100%;"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['Start Time', 'End Time']"
              :default-value="[event.start_date, event.end_date]"
              @change="onChangeDate"
              :disabled="(!event.isdaterange || !editable_form) && !newEvent"
            />
          </div>
        </div>
        <div class='display-card-switch'>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_monday" v-model="event.available_time.monday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('monday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.monday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.monday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.monday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.monday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_tuesday" v-model="event.available_time.tuesday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('tuesday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.tuesday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.tuesday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.tuesday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.tuesday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_wednesday"  v-model="event.available_time.wednesday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('wednesday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.wednesday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.wednesday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.wednesday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.wednesday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_thursday" v-model="event.available_time.thursday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('thursday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.thursday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.thursday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.thursday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.thursday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_friday" v-model="event.available_time.friday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('friday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.friday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.friday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.friday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.friday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_saturday" v-model="event.available_time.saturday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('saturday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.saturday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.saturday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.saturday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.saturday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
          <div class="time">
            <div class="swicth-days">
              <a-switch id="switch_sunday" v-model="event.available_time.sunday.isopen" :disabled="!editable_form && !newEvent"/>
              <label style="padding-left: 10%;">{{$t('sunday')}}</label>
            </div>
            <div class="swicth-days">
              <div style="margin-right: 3%;">
                <a-time-picker v-model="event.available_time.sunday.open" placeholder='Start Time' format="HH:mm" :disabled="(!event.available_time.sunday.isopen || !editable_form) && !newEvent"/>
              </div>
              <div>
                <a-time-picker v-model="event.available_time.sunday.close" placeholder='End Time' format="HH:mm" :disabled="(!event.available_time.sunday.isopen || !editable_form) && !newEvent"/>
              </div>
            </div>
          </div>
        </div>
        <div class="display-action-all" v-if="!newEvent">
          <div>
            <a-switch v-model="event.isactive" @change="e=>openEvent(e)"/>
          </div>
          <div v-if="!editable_form">
            <a-button shape="circle" size="large" icon="edit"  @click="openeditEvent"/>
            <a-popconfirm
              title="Are you sure delete this event ?"
              ok-text="Yes"
              cancel-text="No"
              placement="topRight"
              @confirm="deleteEvent"
            >
              <a-button type="danger" shape="circle" size="large" icon="delete" />
            </a-popconfirm>
          </div>
          <div v-else>
            <a-button shape="circle" size="large" icon="save" @click="editEvent"/>
            <a-button type="danger" shape="circle" size="large" icon="close" @click="canceleditEvent"/>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
  props: ['event', 'newEvent', 'getData'],
  data () {
    return {
      showImage: '',
      editable_form: false
    }
  },
  created () {
    this.showImage = this.event.img
  },
  methods: {
    moment,
    async getBase64 (img, callback) {
      const reader = new FileReader()
      await reader.addEventListener('load', () => callback(reader.result))
      await reader.readAsDataURL(img)
    },
    async beforeUpload (file) {
      await this.getBase64(file, imageUrl => {
        this.showImage = imageUrl
      })
      this.event.img = file
      const isJpgOrPng = file.type === 'image/png' || 'image/jpg' || 'image/jpeg'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload PNG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 0.5
      if (!isLt2M) {
        this.$message.error('Image must smaller than 500Kb!')
      }
      return isJpgOrPng && isLt2M && false
    },
    handleChange (value) {
      this.event.name = value
    },
    onChangeDate (value) {
      this.event.start_date = value[0]
      this.event.end_date = value[1]
    },
    openeditEvent () {
      this.editable_form = true
    },
    openEvent (e) {
      this.event.isactive = e
      this.editEvent()
    },
    editEvent () {
      const bodyFormData = new FormData()
      // Open
      const mondayOpen = this.event.available_time.monday.open ? parseFloat(moment(this.event.available_time.monday.open).format('LT').replace(':', '.')) : 0
      const tuesdayOpen = this.event.available_time.tuesday.open ? parseFloat(moment(this.event.available_time.tuesday.open).format('LT').replace(':', '.')) : 0
      const wednesdayOpen = this.event.available_time.wednesday.open ? parseFloat(moment(this.event.available_time.wednesday.open).format('LT').replace(':', '.')) : 0
      const thursdayOpen = this.event.available_time.thursday.open ? parseFloat(moment(this.event.available_time.thursday.open).format('LT').replace(':', '.')) : 0
      const fridayOpen = this.event.available_time.friday.open ? parseFloat(moment(this.event.available_time.friday.open).format('LT').replace(':', '.')) : 0
      const saturdayOpen = this.event.available_time.saturday.open ? parseFloat(moment(this.event.available_time.saturday.open).format('LT').replace(':', '.')) : 0
      const sundayOpen = this.event.available_time.sunday.open ? parseFloat(moment(this.event.available_time.sunday.open).format('LT').replace(':', '.')) : 0
      // // Close
      const mondayClose = this.event.available_time.monday.close ? parseFloat(moment(this.event.available_time.monday.close).format('LT').replace(':', '.')) : 0
      const tuesdayClose = this.event.available_time.tuesday.close ? parseFloat(moment(this.event.available_time.tuesday.close).format('LT').replace(':', '.')) : 0
      const wednesdayClose = this.event.available_time.wednesday.close ? parseFloat(moment(this.event.available_time.wednesday.close).format('LT').replace(':', '.')) : 0
      const thursdayClose = this.event.available_time.thursday.close ? parseFloat(moment(this.event.available_time.thursday.close).format('LT').replace(':', '.')) : 0
      const fridayClose = this.event.available_time.friday.close ? parseFloat(moment(this.event.available_time.friday.close).format('LT').replace(':', '.')) : 0
      const saturdayClose = this.event.available_time.saturday.close ? parseFloat(moment(this.event.available_time.saturday.close).format('LT').replace(':', '.')) : 0
      const sundayClose = this.event.available_time.sunday.close ? parseFloat(moment(this.event.available_time.sunday.close).format('LT').replace(':', '.')) : 0
      let availabletime = {
        monday: { open: mondayOpen, close: mondayClose, isopen: this.event.available_time.monday.isopen },
        tuesday: { open: tuesdayOpen, close: tuesdayClose, isopen: this.event.available_time.tuesday.isopen },
        wednesday: { open: wednesdayOpen, close: wednesdayClose, isopen: this.event.available_time.wednesday.isopen },
        thursday: { open: thursdayOpen, close: thursdayClose, isopen: this.event.available_time.thursday.isopen },
        friday: { open: fridayOpen, close: fridayClose, isopen: this.event.available_time.friday.isopen },
        saturday: { open: saturdayOpen, close: saturdayClose, isopen: this.event.available_time.saturday.isopen },
        sunday: { open: sundayOpen, close: sundayClose, isopen: this.event.available_time.sunday.isopen }
      }
      availabletime = JSON.stringify(availabletime)
      bodyFormData.append('_id', this.event._id)
      bodyFormData.append('isactive', this.event.isactive)
      bodyFormData.append('isdeleted', this.event.isdeleted)
      bodyFormData.append('name', this.event.name)
      bodyFormData.append('img', this.event.img)
      bodyFormData.append('available_time', availabletime)
      bodyFormData.append('isdaterange', this.event.isdaterange)
      bodyFormData.append('start_date', this.event.start_date ? this.event.start_date : null)
      bodyFormData.append('end_date', this.event.end_date ? this.event.end_date : null)

      axios.put('/1.0/agent/event', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        this.editable_form = false
        this.getData()
        this.$success({
          title: 'Edit Event Success',
          content: (
            <div>
              <p>edit event success</p>
            </div>
          )
        })
      }).catch(() => {
        this.$error({
          title: 'Can not edit event',
          content: 'edit event not found'
        })
      })
    },
    deleteEvent () {
      axios.delete('/1.0/agent/event', { params: { _id: this.event._id } }).then(() => {
        this.$success({
          title: 'Delete Event Success',
          content: (
            <div>
              <p>Delete event success</p>
            </div>
          )
        })
        this.getData()
      }).catch(() => {
        this.$error({
          title: 'Can not delete event',
          content: 'delete event not found'
        })
      })
    },
    canceleditEvent () {
      this.editable_form = false
      this.showImage = this.event.img
    }
  }
}
</script>

<style>
.display-card{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: stretch;
  justify-content: space-around;
}
.display-card-image{
  width: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 2%;
}
.display-card-detail{
  width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 2%;
}
.display-card-switch{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 400px;
  margin: 2%;
}
.display-card-switch .time{
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 1%;
}
.display-card-switch .time .swicth-days{
  width: 35%;
  display: flex;
}
.display-action-all{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
