<template>
  <div>
    <a-modal v-model="visibleModelDeposit" title="Deposit" @ok="handleDeposit">
      <a-form-model :model="formDeposit">
        <a-form-model-item
          label="amount"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input v-model="formDeposit.amount" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="visibleModelWithdraw"
      title="Withdraw"
      @ok="handleWithdraw"
    >
      <a-form-model :model="formWithdraw">
        <a-form-model-item
          label="amount"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input v-model="formWithdraw.amount" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <div class="search-username">
      <div style="padding-right: 1%;">
        <a-input placeholder="username" v-model="username" />
      </div>
      <div>
        <a-button type="primary" @click="searchName">
          {{$t('search')}}
        </a-button>
      </div>
    </div>
    <!-- <div class="flex flex-row items-center w-1/3 py-2 space-x-2">
      <span>Username</span>
      <a-input placeholder="username" v-model="username" />
      <span>{{$t('type')}}</span>
      <a-select
        default-value=""
        style="width: 100%"
        @change="handleMemberTypeChange"
      >
        <a-select-option value="">
          {{$t('all')}}
        </a-select-option>
        <a-select-option value="agent">
          {{$t('agent')}}
        </a-select-option>
        <a-select-option value="user">
          {{$t('member')}}
        </a-select-option>
      </a-select>

      <a-button type="primary" @click="searchName">
        {{$t('search')}}
      </a-button>
    </div> -->
    <div>
      <a-radio-group default-value="agent" button-style="solid" @change="onChange">
        <a-radio-button value="agent">
          {{$t('agent')}}
        </a-radio-button>
        <a-radio-button value="user">
          {{$t('member')}}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div style="padding-top: 1%">
      <a-breadcrumb>
        <a-breadcrumb-item xstyle="color: blue" v-for="(scope, index) in scopes" :key="index"><a @click="callscope(scope)">{{scope}}</a></a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-table
      :columns="columns"
      :loading="loadingData"
      :data-source="myDownline"
      rowKey="_id" size="small"
      :scroll="{ x: 1000 }"
      :pagination="{ defaultPageSize: itemsPerPage, total: totaluser, onChange: pageChange }"
      :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
    >
      <p v-if="profile.username === 'areapin'" :style="{ display: 'flex', alignItems: 'center' }" slot="expandedRowRender" slot-scope="record" >
        <a :style="{ color: 'blue' }" @click="copysecret(record.secret_key)">{{ record.secret_key }}</a>
      </p>
      <span slot="action" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="testDeposit(record._id)">
            <a-icon type="plus-circle" />
          </a-button>
          <a-button type="danger" @click="testWithdraw(record._id)">
            <a-icon type="minus-circle" />
          </a-button>
        </a-button-group>
      </span>
      <div v-for="col in ['status']" :slot="col" slot-scope="text, record" :key="col">
        <a-select
          :default-value="record.status === 'normal' ? 'open': 'close'"
          style="width: 100%"
        >
          <template v-for="(list) in list_status">
            <a-select-option :key="list.value" :value="list.value" @click="(value) => confirm(record._id, value.key)">
              {{ list.label }}
            </a-select-option>
          </template>
        </a-select>
      </div>
      <span slot="edit" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="editData(record._id)">
            <a-icon type="edit" />
          </a-button>
        </a-button-group>
      </span>
      <span v-if="record.type === 'user'" slot="ticket" slot-scope="text, record">
        <a-button-group>
          <a-button type="primary" @click="ticket(record._id)">
            <a-icon type="plus-circle" />
          </a-button>
        </a-button-group>
      </span>
      <span v-else slot="ticket"></span>
      <a v-if="record.type === 'agent'" slot="username" slot-scope="text, record" @click="() => member(record._id, record.username)">{{ text }}</a>
      <a v-else slot="username" slot-scope="text">{{ text }}</a>
      <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ parseFloat(text).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</a></a>
    </a-table>
    <a-modal
      :title="$t('editdata')"
      :visible="visible"
      :footer="null"
      width='70%'
      @cancel="handleCancel"
    >
      <div>
        <template v-if="formuser">
          <editMember v-bind:datauser="userData" v-bind:visible="handleCancel" v-bind:fetchMyDownline="fetchMyDownline"/>
        </template>
        <template v-else>
          <editAgent v-bind:datauser="userData" v-bind:visible="handleCancel" v-bind:fetchMyDownline="fetchMyDownline"/>
        </template>
      </div>
    </a-modal>
    <a-modal
      :title="$t('ticket')"
      :visible="visibleticket"
      :confirm-loading="confirmLoading"
      @ok="addTicket"
      @cancel="handleCancelTicket"
    >
      <div class="main-modal-ticket">
        <div class="main-modal-ticket-title">
          <div class="main-modal-ticket-sub">
            <img
              style="width: 100%; border-radius: 10px;"
              alt="imgEvent"
              src="https://cdn.areaslot.cc/img/items/bronze_ticket.png"
            />
          </div>
          <div class="main-modal-ticket-sub">
            <a-input-number
              style="width: auto;"
              v-model="bronze_ticket"
            />
          </div>
        </div>
        <div class="main-modal-ticket-title">
          <div>
            <img
              style="width: 100%; border-radius: 10px;"
              alt="imgEvent"
              src="https://cdn.areaslot.cc/img/items/silver_ticket.png"
            />
          </div>
          <div>
            <a-input-number
              style="width: auto;"
              v-model="silver_ticket"
            />
          </div>
        </div>
        <div class="main-modal-ticket-title">
          <div>
            <img
              style="width: 100%; border-radius: 10px;"
              alt="imgEvent"
              src="https://cdn.areaslot.cc/img/items/gold_ticket.png"
            />
          </div>
          <div>
            <a-input-number
              style="width: auto;"
              v-model="gold_ticket"
            />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { formItemLayout, formTailLayout } from '../../utils/form_size'
import { get, call, sync } from 'vuex-pathify'
import axios from 'axios'
import editMember from './editMember.vue'
import editAgent from './editAgent.vue'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%',
    align: 'center'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '15%',
    scopedSlots: { customRender: 'username' },
    align: 'center'
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '10%',
    align: 'center'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: '10%',
    align: 'center'
  },
  {
    title: 'Wallettype',
    dataIndex: 'wallettype',
    width: '10%',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10%',
    scopedSlots: { customRender: 'status' },
    align: 'center'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    scopedSlots: { customRender: 'balance' },
    width: '17%',
    align: 'center'
  },
  {
    title: 'Refill/Deduct',
    key: 'action',
    width: '13%',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  },
  {
    title: 'Edit Data',
    key: 'edit',
    width: '10%',
    scopedSlots: { customRender: 'edit' },
    align: 'center'
  },
  {
    title: 'Ticket',
    key: 'ticket',
    width: '8%',
    scopedSlots: { customRender: 'ticket' },
    align: 'center'
  }

]

export default {
  data () {
    return {
      formItemLayout,
      formTailLayout,
      columns,
      selectType: 'agent',
      username: '',
      usernameSecreted: undefined,
      formDeposit: {
        amount: 0
      },
      visibleModelDeposit: false,
      formWithdraw: {
        amount: 0
      },
      visibleModelWithdraw: false,
      visible: false,
      confirmLoading: false,
      userData: [],
      formuser: false,
      visibleticket: false,
      userticket: '',
      bronze_ticket: 0,
      silver_ticket: 0,
      gold_ticket: 0,
      hasErrors,
      myDownline: [],
      loadingData: false,
      totaluser: 0,
      page: 1,
      itemsPerPage: 50,
      scopes: [],
      list_status: [{ label: 'normal', value: 'open' }, { label: 'suspended', value: 'close' }]
    }
  },
  components: {
    editMember,
    editAgent
  },
  async created () {
    await this.myProfile()
    await this.fetchMyDownline()
    this.scopes = [this.profile.username]
  },
  computed: {
    // myDownline: get('member/myDownline'),
    hasError: sync('member/hasError'),
    messageApi: sync('member/message'),
    profile: get('account/profile'),
    permissions: get('account/permissions')
  },
  methods: {
    copysecret (text) {
      navigator.clipboard.writeText(text)
      this.$notification.success({
        message: 'Secret Key is Copied.'
      })
    },
    callscope (username) {
      const scopeindex = this.scopes.findIndex(scope => scope === username)
      this.scopes = this.scopes.slice(0, scopeindex + 1)
      this.getData()
    },
    async fetchMyDownline () {
      this.loadingData = true
      try {
        const { data } = await axios.get('/1.0/agent/mydownlineall', {
          params: {
            username: this.username,
            type: this.selectType,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
        })
        this.loadingData = false
        this.totaluser = data.total
        this.myDownline = data.users
      } catch (error) {
      }
    },
    myProfile: call('account/myProfile'),
    pageChange (page) {
      this.page = page
      this.fetchMyDownline()
    },
    editData (id) {
      if (this.permissions.member_mgm === 'edit' || !this.profile.issup) {
        this.visible = true
        const editUser = this.myDownline.filter(user => user._id === id)
        if (editUser[0].type === 'user') {
          this.formuser = true
        } else {
          this.formuser = false
        }
        this.userData = editUser
      } else {
        this.$message.error('ไม่มีสิทธิ์แก้ไข')
      }
    },
    handleCancel (e) {
      this.visible = false
    },
    handleCancelTicket () {
      this.visibleticket = false
      this.bronze_ticket = 0
      this.silver_ticket = 0
      this.gold_ticket = 0
    },
    ticket (id) {
      if (this.permissions.member_mgm === 'edit' || !this.profile.issup) {
        this.visibleticket = true
        this.userticket = id
      } else {
        this.$message.error('ไม่มีสิทธิ์แก้ไข')
      }
    },
    async addTicket () {
      if (this.bronze_ticket !== null && this.silver_ticket !== null && this.gold_ticket !== null) {
        const values = {
          user_id: this.userticket,
          bronze_ticket: this.bronze_ticket,
          silver_ticket: this.silver_ticket,
          gold_ticket: this.gold_ticket
        }
        try {
          const response = await axios.post(
            '/1.0/user/ticket',
            values,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          if (response.status === 200) {
            this.messageApi = response.data.message
            this.hasError = false
            this.visibleticket = false
            this.bronze_ticket = 0
            this.silver_ticket = 0
            this.gold_ticket = 0
          }
        } catch (error) {
          this.messageApi = error.response.data.message
          this.hasError = true
        }
        if (this.hasError) {
          this.$message.error(this.messageApi)
        } else {
          this.$message.success(this.messageApi)
        }
      } else {
        this.$message.error('Please enter a number')
        this.bronze_ticket = 0
        this.silver_ticket = 0
        this.gold_ticket = 0
      }
    },
    testDeposit (_value) {
      if (this.permissions.deposit === 'edit' || !this.profile.issup) {
        this.usernameSecreted = _value
        this.visibleModelDeposit = true
      } else {
        this.$message.error('ไม่มีสิทธิ์แก้ไข')
      }
    },
    testWithdraw (_value) {
      if (this.permissions.deposit === 'edit' || !this.profile.issup) {
        this.usernameSecreted = _value
        this.visibleModelWithdraw = true
      } else {
        this.$message.error('ไม่มีสิทธิ์แก้ไข')
      }
    },
    async handleDeposit () {
      await this.handleDepositApi(
        this.usernameSecreted,
        this.formDeposit.amount
      )
      this.visibleModelDeposit = false
      this.usernameSecreted = undefined
      this.formDeposit.amount = 0
    },
    async handleWithdraw () {
      await this.handleWithdrawtApi(
        this.usernameSecreted,
        this.formWithdraw.amount
      )
      this.visibleModelWithdraw = false
      this.usernameSecreted = undefined
      this.formDeposit.amount = 0
    },
    async handleDepositApi (username, amount) {
      try {
        await axios.post(
          '/1.0/agent/deposit?_id=' + username,
          {
            amount: parseInt(amount)
          }
        )
        await this.fetchMyDownline()
      } catch (error) {
      }
    },
    async handleWithdrawtApi (username, amount) {
      try {
        await axios.post(
          '/1.0/agent/withdraw?_id=' + username,
          {
            amount: parseInt(amount)
          }
        )
        await this.fetchMyDownline()
      } catch (error) {
      }
    },
    // async handleMemberTypeChange (value) {
    //   this.selectType = value
    //   await this.fetchMyDownline()
    // },
    async onChange (e) {
      this.selectType = e.target.value
      await this.fetchMyDownline()
    },
    async searchName () {
      await this.fetchMyDownline()
    },
    member (val, username) {
      this.scopes.push(username)
      this.$router.push({ path: '/member/submember', query: { agent_id: val, scope: this.scopes } })
    },
    async confirm (id, value) {
      const that = this
      const deleteUser = this.myDownline.filter(user => user._id === id)
      if (deleteUser[0].type === 'agent') {
        this.$confirm({
          title: 'Confirm',
          content: 'Confirm ' + value + ' Agent',
          okText: 'OK',
          cancelText: 'Cancel',
          async onOk () {
            try {
              const response = await axios.put('/1.0/agent/status?agent_id=' + id, { status: value }, { headers: { 'Content-Type': 'application/json' } })
              if (response.status === 200) {
                that.messageApi = response.data.message
                that.hasError = false
              }
            } catch (error) {
              that.messageApi = error.response.data.message
              that.hasError = true
            }
            if (that.hasError) {
              that.$message.error(that.messageApi)
            } else {
              that.$message.success(that.messageApi)
            }
          }
        })
      } else {
        this.$confirm({
          title: 'Confirm',
          content: 'Confirm ' + value + ' User',
          okText: 'OK',
          cancelText: 'Cancel',
          async onOk () {
            try {
              const response = await axios.put('/1.0/user/status?user_id=' + id, { status: value }, { headers: { 'Content-Type': 'application/json' } })
              if (response.status === 200) {
                that.messageApi = response.data.message
                that.hasError = false
              }
            } catch (error) {
              that.messageApi = error.response.data.message
              that.hasError = true
            }
            if (that.hasError) {
              that.$message.error(that.messageApi)
            } else {
              that.$message.success(that.messageApi)
            }
          }
        })
      }
    }
  }
}
</script>

<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
  .main-modal-ticket {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .main-modal-ticket-title {
    width: 30%;
    text-align: center;
  }
  .main-modal-ticket-sub {
    width: auto;
  }
  .search-username {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1%;
  }
</style>
