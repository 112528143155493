<template>
  <div :style="{ paddingLeft: '5px', paddingRight: '5px' }">
    <a-row type="flex" justify="start" align="bottom" style="padding-top: 2%">
      <!-- <a-col :lg="16" :xs="24" :order="2">
        <span class="textbold">{{$t('date')}}</span><br />
        <a-range-picker
          format="YYYY/MM/DD"
          showTime
          @change="onChangeDate"
          @ok="selectDate"
          :value="[stardate, enddate]"
        />
      </a-col> -->
      <a-col
        :lg="6"
        :xs="24"
        :order="1"
        :style="{ paddingTop: '8px', marginRight: '30px' }"
      >
        <span class="textbold">{{ $t("daterange") }}</span> <br />
        <a-select
          default-value="day"
          style="width: 100%"
          @change="changeDateRange"
        >
          <a-select-option value="day">
            {{ $t("today") }}
          </a-select-option>
          <a-select-option value="yesterday">
            {{ $t("yesterday") }}
          </a-select-option>
          <a-select-option value="lastweek">
            {{ $t("lastweek") }}
          </a-select-option>
          <a-select-option value="week">
            {{ $t("week") }}
          </a-select-option>
          <a-select-option value="month">
            {{ $t("month") }}
          </a-select-option>
          <a-select-option value="lastmonth">
            {{ $t("lastmonth") }}
          </a-select-option>
          <a-select-option value="all">
            {{ $t("all") }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :order="2" :style="{ paddingTop: '8px', marginRight: '10px' }">
        <span class="textbold">{{ $t("date") }}</span
        ><br />
        <a-range-picker
          format="YYYY/MM/DD"
          showTime
          style="width: auto"
          @change="onChangeDate"
          @ok="selectDate"
          :value="[stardate, enddate]"
        />
      </a-col>
      <!-- <a-col :order="3"  :style="{ paddingTop: '8px' }">
        <a-date-picker
          v-model="enddate"
          show-time
          format="YYYY/MM/DD"
          placeholder="End"
        />
      </a-col> -->
    </a-row>
    <br />
    <a-row type="flex" justify="space-between">
      <a-col :lg="24" :xs="24" :order="1">
        <a-checkbox
          class="textbold"
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
        >
          {{ $t("selectall") }}
        </a-checkbox>
        <a-checkbox-group
          class="textbold"
          v-model="checkedList"
          :options="plainOptions"
          @change="onChangeProduct"
        />
      </a-col>
      <a-col :lg="24" :xs="24" :order="2" style="margin-top: 20px">
        <a-breadcrumb>
          <a-breadcrumb-item
            xstyle="color: blue"
            v-for="(scope, index) in scopes"
            :key="index"
            ><a @click="callscope(scope)">{{ scope }}</a></a-breadcrumb-item
          >
        </a-breadcrumb>
      </a-col>
      <a-col :lg="24" :xs="24" :order="3" style="margin-top: 20px">
        <span style="color: black; font-weight: 600">User Balance: </span
        ><span style="color: green; font-weight: 600">{{
          numberWithCommas(user_balance)
        }}</span
        ><span style="color: black; font-weight: 600"> THB</span>
      </a-col>
      <a-col :lg="24" :xs="24" :order="4" style="margin-top: 20px">
        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          size="small"
          :loading="loadingData"
          :scroll="{ x: 1500 }"
          :pagination="{
            defaultPageSize: itemsPerPage,
            total: totalbet,
            onChange: pageChange,
          }"
          :rowClassName="
            (record, index) =>
              index === data.length - 1
                ? 'table-last-row'
                : index % 2 === 0
                ? 'table-row-light'
                : 'table-row-dark'
          "
        >
          <div slot="game" slot-scope="text">
            <template >
              <div>
                {{ text }}
              </div>
            </template>
          </div>
          <div slot="name" slot-scope="text, record">
            <template v-if="record.productId === 'SBO'">
              <a-button type="primary" @click="showBet(record.bet_id)"
                >Details</a-button
              >
            </template>
            <template v-else>
              <a v-if="record.productId === 'PGSOFT'" @click="betdetail(record.bet_id, record)">
                {{text}}
              </a>
              <!-- <a v-else-if="record.productId === 'JILI'" @click="betdetail(record.bet_id, record)">
                {{text}}
              </a> -->
              <a v-else-if="record.id !== 'grandtotal'" :style="'color: black'">
                {{text}}
              </a>
              <a v-else :style="'color: black'"
                ><strong>{{ text }}</strong></a
              >
            </template>
          </div>
          <a slot="turnover" slot-scope="text"
            ><a :style="'color: black'">{{ numberWithCommas(text) }}</a></a
          >
          <a slot="amount" slot-scope="text"
            ><a :style="'color: black'">{{ numberWithCommas(text) }}</a></a
          >
          <a slot="memberwl" slot-scope="text"
            ><a
              :style="parseFloat(text) >= 0 ? 'color: green' : 'color: red'"
              >{{ numberWithCommas(text) }}</a
            ></a
          >
          <a slot="agentwl" slot-scope="text"
            ><a
              :style="parseFloat(text) >= 0 ? 'color: green' : 'color: red'"
              >{{ numberWithCommas(text) }}</a
            ></a
          >
          <a slot="resellerwl" slot-scope="text"
            ><a
              :style="parseFloat(text) >= 0 ? 'color: green' : 'color: red'"
              >{{ numberWithCommas(text) }}</a
            ></a
          >
        </a-table>
      </a-col>
    </a-row>
    <a-modal v-model="visible" title="SPORT" width="80%" :footer="null">
      <a-table
        :columns="columnsBet"
        :data-source="dataBet"
        bordered
        size="small"
        :loading="loadingDataBet"
        :scroll="{ x: 1000 }"
      >
        <div slot="detail" slot-scope="text, record">
          <a :style="'color: blue; font-weight: 900'">{{ record.refNo }}</a>
          <br />
          <a :style="'color: black; font-weight: 500'">{{
            record.sportsType
          }}</a
          ><br />
          <a :style="'color: black'">{{ text }}</a>
        </div>
        <div slot="options" slot-scope="text, record">
          <div v-for="(list, index) in record.options" :key="index">
            <a :style="'color: red; font-weight: 800'"
              >{{ list.betOption }}@
            </a>
            <a :style="'color: red; font-weight: 800'">{{ list.hdp }} </a>
            <a :style="'color: red; font-weight: 800'">@{{ list.liveScore }}</a
            ><br :key="list.liveScore + Math.random()" />
            <a :style="'color: black'">{{ list.marketType }}</a
            ><br :key="list.liveScore + Math.random()" />
            <a :style="'color: black; font-weight: 600'">{{ list.match }}</a
            ><br :key="list.liveScore + Math.random()" />
            <a :style="'color: #F60'"
              >{{ list.league }} @{{
                moment(list.kickOffTime).locale("th").format("L").toString()
              }}</a
            >
          </div>
        </div>
        <a slot="odds" slot-scope="text"
          ><a
            :style="
              parseFloat(text) >= 0
                ? 'color: blue; font-weight: 600'
                : 'color: red; font-weight: 600'
            "
            >{{ text }}</a
          ></a
        >
        <a slot="turnover" slot-scope="text"
          ><a
            :style="
              parseFloat(text) >= 0
                ? 'color: green; font-weight: 600'
                : 'color: red; font-weight: 600'
            "
            >{{ numberWithCommas(text) }}</a
          ></a
        >
        <a slot="winlost" slot-scope="text"
          ><a
            :style="
              parseFloat(text) >= 0
                ? 'color: green; font-weight: 600'
                : 'color: red; font-weight: 600'
            "
            >{{ numberWithCommas(text) }}</a
          ></a
        >
        <a slot="status" slot-scope="text"
          ><a
            :style="
              text === 'won'
                ? 'color: green; font-weight: 600'
                : 'color: red; font-weight: 600'
            "
            >{{ text }}</a
          ></a
        >
      </a-table>
    </a-modal>
    <a-modal
      title="Bet Detail"
      :visible="betdetailmodal"
      :width="800"
      :footer="null"
      @cancel="betdetailmodal = false"
    >
      <template>
        <div class="container">
          <iframe
            :src="betdetaillink"
            class="responsive-iframe"
            title="betdetail"
          />
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import { get } from 'vuex-pathify'
const pglist = require('@/assets/pglist.json')

const plainOptions = []
const columns = [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
    width: '7%',
    align: 'center'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '20%'
  },
  {
    title: 'Game',
    dataIndex: 'game',
    key: 'game',
    align: 'center',
    scopedSlots: { customRender: 'game' },
    width: '20%'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' },
    width: '30%'
  },
  {
    title: 'Turnover',
    dataIndex: 'turnover',
    key: 'turnover',
    align: 'center',
    width: '15%',
    scopedSlots: { customRender: 'turnover' }
  },
  {
    title: 'Valid Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    scopedSlots: { customRender: 'amount' },
    width: '15%'
  },
  {
    title: 'Member',
    align: 'center',
    children: [
      {
        title: 'W/L',
        dataIndex: 'memberwl',
        key: 'memberwl',
        align: 'center',
        scopedSlots: { customRender: 'memberwl' },
        width: '15%'
      },
      {
        title: 'Comm',
        dataIndex: 'membercomm',
        key: 'membercomm',
        align: 'center',
        scopedSlots: { customRender: 'membercomm' },
        width: '15%'
      },
      {
        title: 'Total',
        dataIndex: 'memberwl',
        key: 'membertotal',
        align: 'center',
        scopedSlots: { customRender: 'memberwl' },
        width: '15%'
      }
    ]
  },
  {
    title: 'Agent',
    align: 'center',
    children: [
      {
        title: 'W/L',
        dataIndex: 'agentwl',
        key: 'agentwl',
        align: 'center',
        scopedSlots: { customRender: 'agentwl' },
        width: '15%'
      },
      {
        title: 'Comm',
        dataIndex: 'agentcomm',
        key: 'agentcomm',
        align: 'center',
        scopedSlots: { customRender: 'agentcomm' },
        width: '15%'
      },
      {
        title: 'Total',
        dataIndex: 'agentwl',
        key: 'agenttotal',
        align: 'center',
        scopedSlots: { customRender: 'agentwl' },
        width: '15%'
      }
    ]
  },
  {
    title: 'Company',
    align: 'center',
    children: [
      {
        title: 'W/L',
        dataIndex: 'resellerwl',
        key: 'resellerwl',
        align: 'center',
        scopedSlots: { customRender: 'resellerwl' },
        width: '15%'
      },
      {
        title: 'Comm',
        dataIndex: 'resellercomm',
        key: 'resellercomm',
        align: 'center',
        scopedSlots: { customRender: 'resellercomm' },
        width: '15%'
      },
      {
        title: 'Total',
        dataIndex: 'resellerwl',
        key: 'resellertotal',
        align: 'center',
        scopedSlots: { customRender: 'resellerwl' },
        width: '15%'
      }
    ]
  }
]

const columnsBet = [
  {
    title: 'Detail',
    dataIndex: 'detail',
    key: 'detail',
    align: 'center',
    scopedSlots: { customRender: 'detail' },
    width: '15%'
  },
  {
    title: 'Options',
    dataIndex: 'options',
    key: 'options',
    align: 'right',
    scopedSlots: { customRender: 'options' }
  },
  {
    title: 'Odds',
    dataIndex: 'odds',
    key: 'odds',
    align: 'center',
    scopedSlots: { customRender: 'odds' },
    width: '10%'
  },
  {
    title: 'Turnover',
    dataIndex: 'turnover',
    key: 'turnover',
    align: 'center',
    scopedSlots: { customRender: 'turnover' },
    width: '10%'
  },
  {
    title: 'WIN/LOSE',
    dataIndex: 'winlost',
    key: 'winlost',
    align: 'center',
    scopedSlots: { customRender: 'winlost' },
    width: '10%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
    width: '10%'
  }
]
const data = []
const dataBet = []
export default {
  data () {
    return {
      data,
      dataBet,
      columns,
      columnsBet,
      betdetailmodal: false,
      betdetaillink: '',
      checkedList: [],
      plainOptions,
      loadingData: false,
      scopes: [],
      memberscope: '',
      totalbet: 0,
      page: 1,
      itemsPerPage: 51,
      stardate: moment().format('YYYY/MM/DD'),
      enddate: moment().format('YYYY/MM/DD'),
      indeterminate: false,
      checkAll: false,
      visible: false,
      loadingDataBet: false,
      user_balance: 0,
      pglist
    }
  },
  beforeCreate () {
    data.length = 0
  },
  async created () {
    await this.getProduct()
    this.stardate = moment(this.$route.query.stardate).format('YYYY/MM/DD')
    this.enddate = moment(this.$route.query.enddate).format('YYYY/MM/DD')
    this.scopes = this.$route.query.scope
    this.memberscope = this.$route.query.member
    this.getData()
    this.getBalance()
  },
  computed: {
    profile: get('account/profile')
  },
  methods: {
    moment,
    async betdetail (id, record) {
      try {
        // const pg = pglist.find((p) => gameCode === p.no)
        // console.log(gameCode)
        // console.log(pg)
        // console.log(record)
        const { data } = await this.axios.post('/2.0/api/getGameDetail', {
          bet_id: id,
          username: this.memberscope,
          product_id: record.productId
          // bet_id: '7363427646528370081'
          // bet_id: '1525313658750354342'
        })
        if (data.data.link !== null || data.data.link !== '') {
          this.betdetailmodal = true
          this.betdetaillink = data.data.link
        } else {
          this.betdetailmodal = false
        }
      } catch (error) {
        this.$notification.error({
          message: 'แจ้งเตือน',
          description: 'ไม่พบประวัติเบท'
        })
      }
    },
    async getProduct () {
      try {
        const response = await this.axios.get('/1.0/agent/products')
        if (response.status === 200) {
          const conditions = [
            'PRAGMATICC',
            'IRONDOG',
            'RELAX',
            'NOLIMITCITY',
            'BLUEPRINT',
            'YGGDRASIL',
            'IRONDOG',
            'BOOONGO',
            '1X2GAMING',
            'ELK',
            'BETTER',
            'RTG',
            'REVOLVER',
            'KIRON',
            'RELOADED',
            'PUSH',
            'EZUGI',
            'FANTASMA',
            'QUICKSPIN',
            'WAZDAN'
          ]
          response.data.products = response.data.products.filter((product) =>
            conditions.every((c) => c !== product.productId)
          )
          this.plainOptions = response.data.products.map(
            (items) => items.productId
          )
          this.plainOptions.push('QTECH')
        }
      } catch (error) {}
    },
    callscope (username) {
      const scopeindex = this.scopes.findIndex((scope) => scope === username)
      this.scopes = this.scopes.slice(0, scopeindex + 1)
      this.$router.push({
        path: '/report/all',
        query: { scope: this.scopes, agentscope: username }
      })
    },
    pageChange (page) {
      this.page = page
      this.getData()
    },
    async getBalance () {
      try {
        const { data } = await axios.get('/1.0/user/report/balance', {
          params: {
            username: this.memberscope
          }
        })
        this.user_balance = data.balance
      } catch (error) {
        console.log(error)
      }
    },
    async getData () {
      data.length = 0
      this.loadingData = true
      try {
        const { data } = await axios.get('/1.0/agent/report/betmember', {
          params: {
            limit: this.itemsPerPage - 1,
            skip: (this.page - 1) * (this.itemsPerPage - 1),
            scope: this.memberscope,
            stardate: this.stardate,
            enddate: this.enddate,
            codes: JSON.stringify(this.checkedList)
          }
        })
        if (data.totalbet > 0) {
          this.totalbet = data.totalbet
          data.userbets.map((items, index) => {
            if (items) {
              let gamename = '-'
              if (items.games) {
                gamename = items.games.name
              } else {
                const pg = pglist.find((p) => items.gameCode === p.no)
                if (pg) {
                  gamename = pg.gameCode
                } else {
                  gamename = items.gameCode || items.betType
                }
              }
              this.data.push({
                key: index + Math.random(),
                id: items._id,
                number:
                  items._id !== 'grandtotal'
                    ? (this.page - 1) * this.itemsPerPage + index + 1
                    : '',
                date: items.createdAt
                  ? moment(items.createdAt)
                    .locale('th')
                    .format('L')
                    .toString() +
                    ' ' +
                    moment(items.createdAt)
                      .locale('th')
                      .format('LTS')
                      .toString()
                  : '',
                productId: items.productId,
                bet_id: items.bet_id,
                game: items._id !== 'grandtotal' ? items.productId : '',
                name: items._id !== 'grandtotal' ? gamename : items.betType,
                turnover: items.realBets,
                amount: items.realBets,
                membercomm: 0,
                agentcomm: 0,
                resellercomm: 0,
                memberwl: items.winlose_member,
                agentwl: items.winlose_agent,
                resellerwl: items.winlose_reseller
              })
            }
            this.loadingData = false
          })
        } else {
          this.loadingData = false
        }
      } catch (error) {
        this.loadingData = false
      }
    },
    onChangeDate (date, dateString) {
      this.stardate = dateString[0]
      this.enddate = dateString[1]
    },
    selectDate () {
      this.getData()
    },
    changeDateRange (value) {
      switch (value) {
        case 'day':
          this.stardate = moment().format('YYYY/MM/DD')
          this.enddate = moment().format('YYYY/MM/DD')
          break
        case 'yesterday':
          this.stardate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          break
        case 'lastweek':
          this.stardate = moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek')
            .format('YYYY/MM/DD')
          this.enddate = moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
            .format('YYYY/MM/DD')
          break
        case 'week':
          this.stardate = moment()
            .subtract(0, 'weeks')
            .startOf('isoWeek')
            .format('YYYY/MM/DD')
          this.enddate = moment()
            .subtract(0, 'weeks')
            .endOf('isoWeek')
            .format('YYYY/MM/DD')
          break
        case 'month':
          this.stardate = moment()
            .subtract(0, 'months')
            .startOf('months')
            .format('YYYY/MM/DD')
          this.enddate = moment()
            .subtract(0, 'months')
            .endOf('months')
            .format('YYYY/MM/DD')
          break
        case 'lastmonth':
          this.stardate = moment()
            .subtract(1, 'months')
            .startOf('months')
            .format('YYYY/MM/DD')
          this.enddate = moment()
            .subtract(1, 'months')
            .endOf('months')
            .format('YYYY/MM/DD')
          break
        case 'all':
          this.stardate = ''
          this.enddate = ''
          break
        default:
          break
      }
      this.getData()
    },
    onCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      this.getData()
    },
    onChangeProduct () {
      this.getData()
    },
    numberWithCommas (x) {
      x = Number(x)
      return x.toLocaleString()
    },
    async showBet (refnos) {
      this.visible = true
      this.loadingDataBet = true
      try {
        const { data } = await this.axios.get('https://aapi.areaslot.cc/1.0/agent/report/betsbo', {
          params: { refnos: refnos }
        })
        this.dataBet = []
        if (data.bet.length > 0) {
          data.bet.map((item, index) => {
            this.dataBet.push({
              key: index + Math.random(),
              refNo: item.refNo,
              sportsType: item.sportsType,
              detail: item.orderTime
                ? moment(item.orderTime).locale('th').format('L').toString() +
                  ' ' +
                  moment(item.orderTime).locale('th').format('LTS').toString()
                : '',
              options: item.subBet.length > 0 ? item.subBet : [],
              odds: item.odds ? item.odds : '',
              turnover: item.turnover ? item.turnover : '',
              winlost: item.winLost ? item.winLost : '',
              status: item.status ? item.status : ''
            })
          })
          this.loadingDataBet = false
        } else {
          this.loadingDataBet = false
        }
      } catch (error) {
        this.loadingDataBet = false
        console.log(error)
      }
    }
  }
}
</script>
<style>
th {
  background-color: #001529 !important;
  color: white !important;
}
.textbold {
  font-weight: bold;
}
.table-last-row {
  background-color: #ffdeb8c4;
  font-weight: bold;
}
.table-row-light {
  background-color: #ffffff !important;
}
.table-row-dark {
  background-color: #dddddd !important;
}
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
