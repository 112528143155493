<template>
  <div>
    <a-form :form="form">
      <a-row>
        <a-col :sm="24" :md="12">
          <span class="title_page">{{ $t('basicinfo') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('username')"
            >
              <a-input-search
                :xaddon-before="profile.username"
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true
                      }
                    ]
                  }
                ]"
                :placeholder="$t('username')"
                size="default"
                @search="onCheckUsername"
                @keyup="canCreate = false"
              >
                <a-button
                  type="primary"
                  slot="enterButton"
                  :disabled="hasErrors(form.getFieldsError(['username']))"
                >
                  <a-icon
                    type="search"
                    :style="{ fontSize: '20px', marginTop: '5px' }"
                  />
                </a-button>
              </a-input-search>
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('password')"
            >
              <a-input
                :disabled="!canCreate"
                type="password"
                :placeholder="$t('password')"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true
                      }
                    ]
                  }
                ]"
              />
            </a-form-item>
          </div>
        </a-col>
        <a-col :sm="24" :md="12">
          <div style="margin-top: 40px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('nickname')"
            >
              <a-input
                :disabled="!canCreate"
                :placeholder="$t('nickname')"
                v-decorator="['nickname']"
              />
            </a-form-item>

            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('phonenumber')"
            >
              <a-input
                :disabled="!canCreate"
                :placeholder="$t('phonenumber')"
                v-decorator="['phone']"
              />
            </a-form-item>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="top">
        <a-col>
          <a-form-item>
            <a-button
              :disabled="!canCreate"
              type="primary"
              @click="onCreateMember"
            >
              {{ $t('createuser') }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formItemLayout, formTailLayout } from '../../utils/form_size'
import { sync, call } from 'vuex-pathify'
function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}
export default {
  data () {
    return {
      formItemLayout,
      formTailLayout,
      username: '',
      password: '',
      hasErrors,
      // canCreate: false,
      form: this.$form.createForm(this, { name: 'add_user' })
      // messageApi: ''
    }
  },
  computed: {
    hasError: sync('member/hasError'),
    profile: sync('account/profile'),
    canCreate: sync('member/canCreateUser'),
    messageApi: sync('member/message')
  },
  methods: {
    checkUsernameMember: call('member/checkUsernameMember'),
    // createUseranmeMember: call('member/createUsernameMember'),
    async onCheckUsername (value) {
      if (value !== null) {
        if (value.length >= 8) {
          await this.checkUsernameMember({ username: value })
          const _canCreate = this.canCreate
          if (_canCreate) {
            this.$message.success(this.messageApi)
          } else {
            this.$message.error(this.messageApi)
          }
        } else {
          this.$message.error('ต้องมีความยาวมากกว่าหรือเท่ากับ 8 ตัวอักษร')
        }
      }
    },
    async onCreateMember (e) {
      if (this.canCreate) {
        e.preventDefault()
        this.form.validateFieldsAndScroll(async (err, values) => {
          if (!err) {
            try {
              const response = await this.axios.post(
                '/1.0/user',
                values,
                {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              )
              if (response.status === 200) {
                this.messageApi = response.data.message
                this.hasError = false
                this.form.resetFields()
              }
            } catch (error) {
              this.messageApi = error.response.data.message
              this.hasError = true
            }
            if (this.hasError) {
              this.$message.error(this.messageApi)
            } else {
              this.$message.success(this.messageApi)
            }
          }
        })
      }
    }
  }
}
</script>
