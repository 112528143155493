<template>
    <div>
        <a-row type="flex" style="padding-top: 2%">
          <a-col :lg="4" :xs="24" :order="1" style="margin-right: 3px">
            <span class="textbold">{{$t('username')}}</span><br />
            <a-input placeholder="Username" v-model="username"  />
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between" style="padding-top: 2%">
          <a-col :lg="16" :xs="24" :order="3">
            <span class="textbold">{{$t('date')}}</span><br />
            <a-range-picker
              format="YYYY/MM/DD"
              showTime
              style="width: auto;"
              @change="onChangeDate"
              @ok="selectDate"
              :value="[stardate, enddate]"
            />
          </a-col>
          <a-col :lg="3" :xs="24" :order="2">
            <span class="textbold">{{$t('daterange')}}</span> <br />
            <a-select default-value="day" style="width: 100%" @change="changeDateRange">
              <a-select-option value="day">
                {{$t('today')}}
              </a-select-option>
              <a-select-option value="yesterday">
                {{$t('yesterday')}}
              </a-select-option>
              <a-select-option value="lastweek">
                {{$t('lastweek')}}
              </a-select-option>
              <a-select-option value="week">
                {{$t('week')}}
              </a-select-option>
              <a-select-option value="month">
                {{$t('month')}}
              </a-select-option>
              <a-select-option value="lastmonth">
                {{$t('lastmonth')}}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :lg="3" :xs="24" :order="1">
            <span class="textbold">{{$t('type')}}</span> <br />
            <a-radio-group default-value="agent" button-style="solid" @change="onChange">
              <a-radio-button value="agent">
                {{$t('agent')}}
              </a-radio-button>
              <a-radio-button value="user">
                {{$t('member')}}
              </a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
        <br />
        <a-row type="flex" justify="space-between">
            <a-col :lg="2" :xs="24" :order="1">
              <a-button type="primary" @click="getData">
                {{$t('search')}}
              </a-button>
            </a-col>
            <a-col :lg="24" :xs="24" :order="2" style="margin-top: 20px">
                <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="middle"
                :loading="loadingData"
                :scroll="{ x: 'calc(700px + 50%)', y: 700 }"
                :pagination="{ defaultPageSize: itemsPerPage, total: totaluser, onChange: pageChange }"
                :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
                >
                  <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
                  <span slot="detail" slot-scope="text, record">
                    <a-button-group>
                      <a-button type="primary" @click="detailTransfer(record.id)">
                        <a-icon type="eye" />
                      </a-button>
                    </a-button-group>
                  </span>
                </a-table>
            </a-col>
        </a-row>
        <a-modal
          :title="$t('reporttransfer')"
          :visible="visible"
          width="80%"
          :footer="null"
          @cancel="handleCancel"
        >
          <a-table
            :columns="columnsTransfers"
            :data-source="dataTransfers"
            bordered
            size="middle"
            :loading="loadingDataTransfers"
            :scroll="{ x: 1000 }"
            :pagination="{ defaultPageSize: itemsPerPagetransfer, total: totaltransfer, onChange: pageChangeTransfer }"
            :rowClassName="(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
          >
            <a slot="amount" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
          </a-table>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

const columns = [
  {
    title: 'Username',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    align: 'center',
    width: '15%',
    scopedSlots: { customRender: 'balance' }
  },
  {
    title: 'Details',
    dataIndex: 'detail',
    key: 'detail',
    align: 'center',
    width: '15%',
    scopedSlots: { customRender: 'detail' }
  }
]

const columnsTransfers = [
  {
    title: 'Username',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '15%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: '10%'
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    align: 'center',
    width: '8%'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: '20%',
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
    width: '20%'
  },
  {
    title: 'By',
    dataIndex: 'by',
    key: 'by',
    align: 'center',
    width: '15%'
  }
]

const data = []

const dataTransfers = []

export default {
  data () {
    return {
      data,
      columns,
      columnsTransfers,
      dataTransfers,
      loadingData: false,
      checkedList: [],
      stardate: moment().format('YYYY/MM/DD'),
      enddate: moment().format('YYYY/MM/DD'),
      selectType: 'agent',
      username: '',
      totaluser: 0,
      page: 1,
      itemsPerPage: 50,
      visible: false,
      loadingDataTransfers: false,
      totaltransfer: 0,
      pagetransfer: 1,
      itemsPerPagetransfer: 50
    }
  },
  beforeCreate () {
    data.length = 0
  },
  async created  () {
    this.getData()
  },
  methods: {
    moment,
    async getData () {
      data.length = 0
      this.loadingData = true
      try {
        await axios.get('/1.0/agent/report/member', {
          params: {
            username: this.username,
            type: this.selectType,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
        }).then((res) => {
          this.totaluser = res.data.total
          res.data.users.map((items, index) => {
            if (items.type === 'agent') {
              data.push({
                key: index + Math.random(),
                id: items._id,
                name: items.username,
                nickname: items.nickname ? items.nickname : '-',
                type: items.type,
                currency: items.currency,
                balance: items.balance.toFixed(2)
              })
            } else {
              data.push({
                key: index + Math.random(),
                id: items._id,
                name: items.username,
                nickname: items.nickname ? items.nickname : '-',
                currency: items.currency,
                type: items.type,
                balance: items.balance.toFixed(2)
              })
            }
          })
          this.loadingData = false
        })
      } catch (error) {
        this.loadingData = false
      }
    },
    async detailTransfer (id) {
      this.visible = true
      dataTransfers.length = 0
      this.loadingDataTransfers = true
      try {
        await axios.get('/1.0/agent/report/transfer', {
          params: {
            user_id: id,
            type: this.selectType,
            stardate: this.stardate,
            enddate: this.enddate,
            limit: this.itemsPerPagetransfer,
            skip: (this.pagetransfer - 1) * this.itemsPerPagetransfer
          }
        }).then((res) => {
          this.totaluser = res.data.total
          res.data.paymentlogs.map((items, index) => {
            if (items.targettype === 'agent') {
              dataTransfers.push({
                key: index + Math.random(),
                id: items._id,
                name: items.agent.username,
                type: items.targettype,
                currency: items.agent.currency,
                amount: items.amount.toFixed(2),
                time: moment(items.createdAt).format('DD-MM-YYYY HH:mm:ss'),
                by: items.txid ? 'Auto' : items.byagents.username
              })
            } else {
              dataTransfers.push({
                key: index + Math.random(),
                id: items._id,
                name: items.user.username,
                currency: items.user.currency,
                type: items.targettype,
                amount: items.amount.toFixed(2),
                time: moment(items.createdAt).format('DD-MM-YYYY HH:mm:ss'),
                by: items.txid ? 'Auto' : items.byagents.username
              })
            }
          })
          this.loadingDataTransfers = false
        })
      } catch (error) {
        this.loadingDataTransfers = false
      }
    },
    onChangeDate (date, dateString) {
      if (dateString.length > 0) {
        this.stardate = dateString[0]
        this.enddate = dateString[1]
      } else {
        this.stardate = ''
        this.enddate = ''
      }
    },
    pageChange (page) {
      this.page = page
      this.getData()
    },
    pageChangeTransfer (page) {
      this.pagetransfer = page
      this.detailTransfer()
    },
    changeDateRange (value) {
      switch (value) {
        case 'day':
          this.stardate = moment().format('YYYY/MM/DD')
          this.enddate = moment().format('YYYY/MM/DD')
          break
        case 'yesterday':
          this.stardate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          break
        case 'lastweek':
          this.stardate = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY/MM/DD')
          break
        case 'week':
          this.stardate = moment().subtract(0, 'weeks').startOf('isoWeek').format('YYYY/MM/DD')
          this.enddate = moment().subtract(0, 'weeks').endOf('isoWeek').format('YYYY/MM/DD')
          break
        case 'month':
          this.stardate = moment().subtract(0, 'months').startOf('months').format('YYYY/MM/DD')
          this.enddate = moment().subtract(0, 'months').endOf('months').format('YYYY/MM/DD')
          break
        case 'lastmonth':
          this.stardate = moment().subtract(1, 'months').startOf('months').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'months').endOf('months').format('YYYY/MM/DD')
          break
        case 'all':
          this.stardate = ''
          this.enddate = ''
          break
        default:
          break
      }
    },
    async onChange (e) {
      this.selectType = e.target.value
      await this.getData()
    },
    selectDate () {
      // this.getData()
    },
    handleCancel (e) {
      this.visible = false
    },
    numberWithCommas (x) {
      x = Number(x)
      return x.toLocaleString()
    }
  }
}
</script>
<style>
  .textbold {
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #fbfbfb;
  }
</style>
