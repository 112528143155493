import { make } from 'vuex-pathify'
import axios from 'axios'
import { isLoggedIn, isSub, clearAuthToken } from '../utils/auth'

// properties
const state = {
  profile: {
    balance: 0,
    type: '',
    status: '',
    username: '',
    nickname: '',
    commission: {
      max: 0
    },
    issup: false
  },
  permissions: {
    member_mgm: '',
    report: '',
    deposit: '',
    api_key: ''
  }
}

const actions = {
  async myProfile ({ commit }) {
    try {
      if (isLoggedIn()) {
        const { data } = await axios.get('/1.0/agent/myprofile')
        // state.profile = data
        if (data.ispassjustchange) {
          clearAuthToken()
          state.profile = {}
          window.location.href = '/login'
          // this.$router.push('/login')
        }
        commit('SET_PROFILE', data)
        if (isSub()) {
          const { data } = await axios.get('/1.0/agent/mysubaccount')
          commit('SET_USERNAMESUB', data.subaccount.username)
          commit('SET_PERMISSION', data.subaccount.permissions)
        }
      }
    } catch (error) {
      clearAuthToken()
      state.profile = {}
      window.location.href = '/login'
    }
  },
  logout () {
    state.profile = {}
  }
}

const mutations = {
  ...make.mutations(state),
  SET_PROFILE (state, value) {
    state.profile = value
    state.profile.issup = isSub()
  },
  SET_PERMISSION (state, value) {
    state.permissions = value
  },
  SET_USERNAMESUB (state, value) {
    state.profile.username = value
  }
}

const getters = {
  profile (state) {
    return state.profile
  },
  balance (state) {
    return state.profile.balance || 0
  },
  commissionMax (state) {
    return state.profile.commission.max || 0
  },
  permissions (state) {
    return state.permissions
  }
}

// store
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
