<template>
  <div :style="{ paddingLeft: '5px', paddingRight: '5px', paddingTop: '10px' }">
    <!-- this is report all page -->
    <a-row type="flex" justify="space-between">
      <a-col :lg="6" :md="12" :xs="24">
        <span class="textbold">{{$t('username')}}:</span><br />
        <a-input :placeholder="$t('username')" @change="searchChange" />
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" align="bottom">
      <a-col :lg="6" :xs="24" :order="1" :style="{ paddingTop: '8px', marginRight: '30px' }">
        <span class="textbold">{{$t('daterange')}}</span> <br />
        <a-select default-value="day" style="width: 100%" @change="changeDateRange">
          <a-select-option value="day">
            {{$t('today')}}
          </a-select-option>
          <a-select-option value="yesterday">
            {{$t('yesterday')}}
          </a-select-option>
          <a-select-option value="lastweek">
            {{$t('lastweek')}}
          </a-select-option>
          <a-select-option value="week">
            {{$t('week')}}
          </a-select-option>
          <a-select-option value="month">
            {{$t('month')}}
          </a-select-option>
          <a-select-option value="lastmonth">
            {{$t('lastmonth')}}
          </a-select-option>
          <!-- <a-select-option value="all">
            {{$t('all')}}
          </a-select-option> -->
        </a-select>
      </a-col>
      <a-col :lg="6" :xs="24" :order="1"  :style="{ paddingTop: '8px', marginRight: '10px' }">
        <span class="textbold">{{$t('date')}}</span><br />
        <a-range-picker
          format="YYYY/MM/DD"
          style="width: auto;"
          showTime
          @change="onChangeDate"
          :value="[stardate, enddate]"
        />

      </a-col>
      <!-- <a-col :order="3"  :style="{ paddingTop: '8px' }">
        <a-date-picker
          v-model="enddate"
          show-time
          format="YYYY/MM/DD"
          placeholder="End"
        />
      </a-col> -->
    </a-row>
    <br />
    <a-row type="flex" justify="space-between">
      <a-col :lg="24" :xs="24" :order="1">
      <a-checkbox class="textbold" :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
        {{$t('selectall')}}
      </a-checkbox>
      <a-checkbox-group class="textbold" v-model="checkedList" :options="plainOptions" @change="onChangeProduct" />
      </a-col>
      <a-col :lg="24" :xs="24" :order="2" style="padding-top: 1%">
        <a-button :disabled="loadingData" type="primary" @click="search">
          {{$t('search')}}
        </a-button>
      </a-col>
      <a-col :lg="24" :xs="24" :order="3" style="margin-top: 20px">
        <a-breadcrumb>
          <a-breadcrumb-item xstyle="color: blue" v-for="(scope, index) in scopes" :key="index"><a @click="callscope(scope)">{{scope}}</a></a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
      <a-col :lg="24" :xs="24" :order="4" style="margin-top: 20px">
        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          size="small"
          :loading="loadingData"
          :scroll="{ x: 1500 }"
          :pagination="{ defaultPageSize: itemsPerPage, total: totaluser, onChange: pageChange }"
          :rowClassName="(record, index) => index === data.length - 1 ? 'table-last-row' : index % 2 === 0 ? 'table-row-light' :  'table-row-dark'"
        >
          <div slot="name" slot-scope="text, record" >
            <a v-if="record.level === 'Agent'" @click="() => member(text)"><strong>{{ text }}</strong></a>
            <a v-else @click="() => betmember(text)"><strong>{{ text }}</strong></a>
          </div>
          <a slot="totalwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
          <a slot="balance" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
          <a slot="turnover" slot-scope="text"><a :style="'color: black'">{{ numberWithCommas(text) }}</a></a>
          <div slot="level" slot-scope="text" style=""><strong>{{text}}</strong></div>
          <!-- <a slot="event" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a> -->
          <a slot="memberwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
          <a slot="agentwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
          <a slot="resellerwl" slot-scope="text"><a :style="parseFloat(text) >= 0 ? 'color: green':'color: red'">{{ numberWithCommas(text) }}</a></a>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { get } from 'vuex-pathify'

const plainOptions = []
const columns = [
  { title: 'Username', dataIndex: 'name', key: 'name', scopedSlots: { customRender: 'name' }, width: 100, align: 'center', fixed: 'left' },
  { title: 'Nickname', dataIndex: 'nickname', key: 'nickname', align: 'center', width: '12%' },
  { title: 'Level', dataIndex: 'level', key: 'level', align: 'center', scopedSlots: { customRender: 'level' }, width: '10%' },
  // {
  //   title: 'Currency',
  //   dataIndex: 'currency',
  //   key: 'currency',
  //   align: 'center',
  //   width: '7%'
  // },
  // {
  //   title: 'Wallet Type',
  //   dataIndex: 'wallettype',
  //   key: 'wallettype',
  //   align: 'center',
  //   width: '8%'
  // },
  { title: 'Valid turn', dataIndex: 'turnover', key: 'turnover', align: 'center', scopedSlots: { customRender: 'turnover' }, width: '17%' },
  { title: 'Member W/L', dataIndex: 'totalwl', key: 'totalwl', align: 'center', scopedSlots: { customRender: 'totalwl' }, width: '17%' },
  {
    title: 'Member',
    align: 'center',
    children: [
      { title: 'W/L', dataIndex: 'memberwl', key: 'memberwl', align: 'center', scopedSlots: { customRender: 'memberwl' }, width: '17%' },
      { title: 'Comm', dataIndex: 'membercomm', key: 'membercomm', align: 'center', scopedSlots: { customRender: 'membercomm' }, width: '17%' },
      { title: 'Total', dataIndex: 'memberwl', key: 'membertotal', align: 'center', scopedSlots: { customRender: 'memberwl' }, width: '17%' }
    ]
  },
  {
    title: 'Agent',
    align: 'center',
    children: [
      { title: 'W/L', dataIndex: 'agentwl', key: 'agentwl', align: 'center', scopedSlots: { customRender: 'agentwl' }, width: '17%' },
      { title: 'Comm', dataIndex: 'agentcomm', key: 'agentcomm', align: 'center', scopedSlots: { customRender: 'agentcomm' }, width: '17%' },
      { title: 'Total', dataIndex: 'agentwl', key: 'agenttotal', align: 'center', scopedSlots: { customRender: 'agentwl' }, width: '17%' }
    ]
  },
  {
    title: 'Company',
    align: 'center',
    children: [
      { title: 'W/L', dataIndex: 'resellerwl', key: 'resellerwl', align: 'center', scopedSlots: { customRender: 'resellerwl' }, width: '17%' },
      { title: 'Comm', dataIndex: 'resellercomm', key: 'resellercomm', align: 'center', scopedSlots: { customRender: 'resellercomm' }, width: '17%' },
      { title: 'Total', dataIndex: 'resellerwl', key: 'resellertotal', align: 'center', scopedSlots: { customRender: 'resellerwl' }, width: '17%' }
    ]
  }
]

const data = []

export default {
  data () {
    return {
      data,
      columns,
      checkedList: ['sbo', 'sa', 'qt', 'sexy', 'jili', 'km', 'joker', 'pp', 'pg', 'evl', 'sp', 'netent', 'rt', 'evp'],
      plainOptions,
      loadingData: false,
      scopes: [],
      agentscope: '',
      username: '',
      stardate: moment().format('YYYY/MM/DD'),
      enddate: moment().format('YYYY/MM/DD'),
      indeterminate: false,
      checkAll: true,
      totaluser: 0,
      page: 1,
      itemsPerPage: 51
    }
  },
  beforeCreate () {
    data.length = 0
  },
  async created  () {
    await this.getProduct()
    if (this.$route.query.scope !== undefined) {
      this.scopes = this.$route.query.scope
      this.agentscope = this.$route.query.agentscope
    } else {
      this.scopes = [this.profile.username]
    }
    // this.getData()
  },
  watch: {
    profile: {
      handler () {
        this.scopes = [this.profile.username]
      },
      deep: true
    }
  },
  computed: {
    profile: get('account/profile'),
    today () {
      return new Date()
    },
    month () {
      return this.today.setDate(this.today.getDate() + 30)
    }
  },
  methods: {
    moment,
    callscope (username) {
      this.page = 1
      const scopeindex = this.scopes.findIndex(scope => scope === username)
      this.scopes = this.scopes.slice(0, scopeindex + 1)
      this.agentscope = username
      this.getData()
    },
    member (val) {
      this.page = 1
      this.agentscope = val
      this.getData()
      this.scopes.push(val)
    },
    betmember (val) {
      this.scopes.push(val)
      this.$router.push({ path: '/report/reportbetmember', query: { scope: this.scopes, member: val, stardate: this.stardate, enddate: this.enddate } })
    },
    async getProduct () {
      try {
        const response = await this.axios.get('/1.0/agent/products')
        console.log(response)
        if (response.status === 200) {
          response.data.products = response.data.products.filter(product => product.productId !== 'PRAGMATICC')
          this.plainOptions = response.data.products.map((items) => (items.code))
          this.plainOptions = [...new Set(this.plainOptions)]
        }
      } catch (error) {
      }
    },
    search () {
      this.getData(this.username)
    },
    async getData (user) {
      data.length = 0
      this.loadingData = true
      try {
        if (this.checkedList.length > 0) {
          const { data } = await axios.get('/1.0/agent/reportnew', {
            params: {
              scope: this.agentscope,
              username: this.username,
              stardate: this.stardate,
              enddate: this.enddate,
              codes: JSON.stringify(this.checkedList),
              limit: this.itemsPerPage - 1,
              skip: (this.page - 1) * (this.itemsPerPage - 1)
            }
          })
          this.data = []
          this.totaluser = data.total
          this.data = data.data.map((items, index) => {
            return {
              key: index + Math.random(),
              id: items._id,
              name: items.username,
              nickname: items.nickname,
              level: items.level,
              turnover: items.turnover,
              totalwl: items.totalwinlose,
              membercomm: 0,
              agentcomm: 0,
              resellercomm: 0,
              memberwl: items.totalwinlose_member,
              agentwl: items.totalwinlose_agent,
              resellerwl: items.totalwinlose_reseller
            }
          })
        } else {
          this.data = []
        }
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
      }
    },
    pageChange (page) {
      this.page = page
      this.getData(this.username)
    },
    onSearch (value) {
      if (value === '') {
        this.getData()
      } else {
        this.getData(value)
      }
      this.username = value
    },
    onChangeDate (date, dateString) {
      this.stardate = dateString[0]
      this.enddate = dateString[1]
    },
    numberWithCommas (x) {
      x = Number(x)
      return x.toLocaleString()
    },
    changeDateRange (value) {
      switch (value) {
        case 'day':
          this.stardate = moment().format('YYYY/MM/DD')
          this.enddate = moment().format('YYYY/MM/DD')
          break
        case 'yesterday':
          this.stardate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'days').format('YYYY/MM/DD')
          break
        case 'lastweek':
          this.stardate = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY/MM/DD')
          break
        case 'week':
          this.stardate = moment().subtract(0, 'weeks').startOf('isoWeek').format('YYYY/MM/DD')
          this.enddate = moment().subtract(0, 'weeks').endOf('isoWeek').format('YYYY/MM/DD')
          break
        case 'month':
          this.stardate = moment().subtract(0, 'months').startOf('months').format('YYYY/MM/DD')
          this.enddate = moment().subtract(0, 'months').endOf('months').format('YYYY/MM/DD')
          break
        case 'lastmonth':
          this.stardate = moment().subtract(1, 'months').startOf('months').format('YYYY/MM/DD')
          this.enddate = moment().subtract(1, 'months').endOf('months').format('YYYY/MM/DD')
          break
        case 'all':
          this.stardate = ''
          this.enddate = ''
          break
        default:
          break
      }
    },
    searchChange (event) {
      this.username = event.target.value
      // if (value === '') {
      //   this.getData()
      // }
    },
    onCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      // console.log(this.checkedList)
      // this.getData(this.username)
    },
    onChangeProduct () {
      // this.getData(this.username)
    }
  }
}
</script>
<style>
  th {
    background-color: #001529 !important;
    color: white !important;
  }
  .textbold {
    font-weight: bold;
  }
  .table-last-row {
    background-color: #ffdeb8c4;
    font-weight: bold;
  }
  .table-row-light {
    background-color: #ffffff !important;
  }
  .table-row-dark {
      background-color: #dddddd !important;
  }
</style>
