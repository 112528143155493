import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const messages = {
  en: {
    logout: 'Log out',
    all: 'All',
    search: 'Search',
    type: 'Type',
    alltypes: 'All Types',
    // dashboard
    yourcredit: 'Your Credit',
    yourmember: 'Your Member',
    agent: 'Agent',
    newmember: 'New Member',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    yesterday: 'Yesterday',
    lastweek: 'Last Week',
    lastmonth: 'Last Month',
    selectall: 'Select All',
    balanceinfo: 'Balance Information',
    balanceday: 'Balance Day',
    winloseday: 'Win/Lose Day',
    balanceyesterday: 'Balance yesterday',
    winloseyesterday: 'Win/Lose yesterday',
    // sidebar
    dashboard: 'Dashboard',
    account: 'Account',
    profile: 'Profile',
    password: 'Password',
    membermgm: 'Member Mgm.',
    addagent: 'Add Agent',
    addmember: 'Add Member',
    memberagents: 'Member & Agents',
    addsubaccount: 'Add Sub Account',
    subaccounts: 'Sub Accounts',
    report: 'Report',
    reportall: 'Report All',
    wlmember: 'W/L Member',
    payment: 'Payment',
    depowithdraw: 'Deposit / Withdrawal',
    suggestion: 'Suggestion',
    member: 'Member',
    apikey: 'API Key',
    seamless: 'Seamless Key',
    transfer: 'Transfer Key',
    // profile
    basicinfo: 'Basic Info',
    credit: 'Credit',
    username: 'Username',
    phonenumber: 'Phone Number',
    name: 'Name',
    nickname: 'Nickname',
    currency: 'Currency',
    pt: 'PT',
    updateinfo: 'Update Info',
    // password
    changepassword: 'Change Password',
    oldpassword: 'Old Password',
    newpassword: 'New Password',
    confirmpassword: 'Confirm Password',
    savenewpassword: 'Save New Password',
    // member
    etcsetting: 'Etc Setting',
    creditsettings: 'Credit Settings',
    ourpt: 'Our PT(%)',
    givept: 'Given PT By(%)',
    agenttype: 'Agent Type',
    wallettype: 'Wallet Type',
    createuser: 'Create User',
    // sub account
    loginname: 'Login Name',
    permissions: 'Permission',
    event: 'Event',
    eventall: 'Event All',
    addevent: 'Add Event',
    cancel: 'Cancel',
    save: 'Save',
    newevent: 'New Event',
    openclose: 'Open/Close',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    deposit: 'Deposit',
    onlineplayer: 'Online player',
    guideline: 'guideline',
    product: 'Games',
    productall: 'Show Games',
    openclosegames: 'Games Downline',
    addeditgames: 'Add/Edit Games',
    agentcommission: 'Agent Commission',
    edit: 'Edit',
    date: 'Date',
    daterange: 'Date Range',
    pickday: 'Pick a day',
    editagent: 'Edit Agent',
    edituser: 'Edit User',
    editdata: 'Edit Data',
    bettype: 'Bet Type',
    dontchang: "*Leave it if you don't want to change",
    reportmember: 'Report Member',
    reporttransfer: 'Report Transfer',
    today: 'To Day',
    ticket: 'Ticket',
    checkusername: 'Must be 8 characters long',
    confirmassword: 'confirmassword'
  },
  th: {
    logout: 'ออกจากระบบ',
    all: 'ทั้งหมด',
    search: 'ค้นหา',
    type: 'ประเภท',
    alltypes: 'ทุกประเภท',
    // dashboard
    yourcredit: 'เครดิตของคุณ',
    yourmember: 'สมาชิกของคุณ',
    agent: 'ตัวแทน',
    newmember: 'สมาชิกใหม่',
    day: 'วัน',
    week: 'สัปดาห์',
    month: 'เดือน',
    yesterday: 'เมื่อวาน',
    lastweek: 'สัปดาห์ที่แล้ว',
    lastmonth: 'เดือนที่แล้ว',
    selectall: 'เลือกทั้งหมด',
    balanceinfo: 'ข้อมูลยอดเล่น',
    balanceday: 'ยอดเล่นวันนี้',
    winloseday: 'ยอดแพ้ชนะวันนี้',
    balanceyesterday: 'ยอดเล่นเมื่อวาน',
    winloseyesterday: 'ยอดแพ้ชนะเมื่อวาน',
    // sidebar
    dashboard: 'แดชบอร์ด',
    account: 'บัญชีผู้ใช้',
    profile: 'ข้อมูลส่วนตัว',
    password: 'รหัสผ่าน',
    membermgm: 'การจัดการสมาชิก',
    addagent: 'เพิ่มตัวแทน',
    addmember: 'เพิ่มสมาชิก',
    memberagents: 'สมาชิกและตัวแทน',
    addsubaccount: 'เพิ่มบัญชีย่อย',
    subaccounts: 'บัญชีย่อย',
    report: 'รายงาน',
    reportall: 'รายงานทั้งหมด',
    wlmember: 'แพ้ชนะสมาชิก',
    payment: 'การชำระเงิน',
    depowithdraw: 'ฝาก / ถอน',
    suggestion: 'ข้อแนะนำ',
    member: 'สมาชิก',
    apikey: 'API Key',
    seamless: 'Seamless Key',
    transfer: 'Transfer Key',
    // profile
    basicinfo: 'ข้อมูลพื้นฐาน',
    credit: 'ยอดเงิน',
    username: 'ชื่อผู้ใช้',
    phonenumber: 'เบอร์โทรศัพท์',
    name: 'ชื่อ',
    nickname: 'ชื่อเล่น',
    currency: 'สกุลเงิน',
    pt: 'เปอร์เซนต์ของเรา',
    updateinfo: 'แก้ไขข้อมูล',
    // password
    changepassword: 'เปลี่ยนรหัสผ่าน',
    oldpassword: 'รหัสผ่านเดิม',
    newpassword: 'รหัสผ่านใหม่',
    confirmpassword: 'ยืนยันรหัสผ่าน',
    savenewpassword: 'บันทึกรหัสผ่านใหม่',
    // member
    etcsetting: 'ตั้งค่าอื่นๆ',
    creditsettings: 'ตั้งค่ายอดเงิน',
    ourpt: 'เปอร์เซนต์ของเรา(%)',
    givept: 'ให้เปอร์เซนต์ตาม(%)',
    agenttype: 'ประเภทตัวแทน',
    wallettype: 'ประเภทการเดิมพัน',
    loginname: 'ชื่อเข้าสู่ระบบ',
    permissions: 'สิทธิ์การเข้าใช้งาน',
    createuser: 'สร้าง',
    event: 'กิจกรรม',
    eventall: 'กิจกรรมทั้งหมด',
    addevent: 'เพิ่มกิจกรรม',
    cancel: 'ยกเลิก',
    save: 'บันทึก',
    newevent: 'กิจกรรมใหม่',
    openclose: 'วันที่เปิด/ปิด',
    monday: 'จันทร์',
    tuesday: 'อังคาร',
    wednesday: 'พุธ',
    thursday: 'พฤหัสบดี',
    friday: 'ศุกร์',
    saturday: 'เสาร์',
    sunday: 'อาทิตย์',
    deposit: 'การฝาก',
    onlineplayer: 'ผู้เล่นออนไลน์',
    guideline: 'ข้อแนะนำ',
    product: 'เกมส์',
    productall: 'เกมส์ทั้งหมด',
    openclosegames: 'เกมส์ตัวแทน',
    addeditgames: 'เพิ่ม/แก้ไข เกมส์',
    agentcommission: 'ค่าคอมมิชชั่นตัวแทน',
    edit: 'แก้ไข',
    date: 'วันที่',
    daterange: 'ตัวเลือกวันที่',
    pickday: 'เลือกวัน',
    editagent: 'แก้ไขข้อมูลตัวแทน',
    edituser: 'แก้ไขข้อมูลสมาชิก',
    editdata: 'แก้ไขข้อมูล',
    bettype: 'ประเภทการเดิมพัน',
    dontchang: '*เว้นไว้ถ้าไม่ต้องเปลี่ยน',
    reportmember: 'รายงานสมาชิก',
    reporttransfer: 'รายงานเติม-ถอนเครดิต',
    today: 'วันนี้',
    ticket: 'ตั๋ว',
    checkusername: 'ต้องมีความยาวมากกว่าหรือเท่ากับ 8 ตัวอักษร',
    confirmassword: 'ยืนยันรหัสผ่าน'
  }
}
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'th', // set fallback locale
  messages // set locale messages
})
export default i18n
