<template>
  <div>
      <a-form :form="form">
        <a-row>
          <a-col :sm="24" :md="12">
            <span style="font-weight: bold;">{{$t('basicinfo')}}</span> <br/>
              <div style="margin-top: 20px;">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('loginname')"
                >
                  <a-input
                    :placeholder="$t('loginname')"
                    v-decorator="[
                      'username',
                      {
                        rules: [
                          {
                            required: true
                          }
                        ]
                      }
                    ]"
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('password')"
                >
                  <a-input
                    :placeholder="$t('password')"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true
                          },
                          {
                            validator: validateToNextPassword
                          }
                        ]
                      }
                    ]"
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  :label="$t('confirmassword')"
                >
                  <a-input
                    :placeholder="$t('confirmassword')"
                    v-decorator="[
                      'confirm',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Confirm Password is required'
                          },
                          {
                            validator: compareToFirstPassword
                          }
                        ]
                      }
                    ]"
                    type="password"
                    @blur="handleConfirmBlur"
                  />
                </a-form-item>
              </div>
          </a-col>
          <a-col :sm="24" :md="12">
            <div style="margin-top: 40px;">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('nickname')"
              >
                <a-input
                  :placeholder="$t('nickname')"
                  v-decorator="['nickname']"
                />
              </a-form-item>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('phonenumber')"
              >
                <a-input
                  :placeholder="$t('phonenumber')"
                  v-decorator="['phone']"
                />
              </a-form-item>
            </div>
          </a-col>
        </a-row>
        <div>
                <span style="font-weight: bold;">{{$t('permissions')}}</span> <br/>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" :label="$t('membermgm')">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_membermgm">
                    <a-radio :value="i.value">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" :label="$t('report')">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_report">
                    <a-radio :value="i.value" :disabled="i.value === 'edit'">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" :label="$t('deposit')">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_deposit">
                    <a-radio :value="i.value">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="API Key">
                  <a-radio-group v-for="(i,index) in permission" :key="index" v-model="permission_api">
                    <a-radio :value="i.value" :disabled="i.value === 'edit'">
                      <span :style="{color: `${i.color}`}">{{i.title}}</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
        <a-row>
          <a-col>
            <div>
              <a-form-item :label-col="formTailLayout.labelCol" :wrapper-col="formTailLayout.wrapperCol">
                <a-button type="primary" @click="submit">
                  เพิ่มบัญชีย่อย
                  <!-- {{$t('updateinfo')}} -->
                </a-button>
              </a-form-item>
            </div>
          </a-col>
        </a-row>
      </a-form>
  </div>
</template>
<script>
import { call, get, sync } from 'vuex-pathify'
import { clearAuthToken } from '../../utils/auth'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 }
}
const formTailLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 3, offset: 10 }
}

export default {
  data () {
    return {
      permission_membermgm: 'close',
      permission_report: 'close',
      permission_deposit: 'close',
      permission_api: 'close',
      formItemLayout,
      formTailLayout,
      hasErrors,
      permission: [{ title: 'ปิด', value: 'close', color: 'red' }, { title: 'มีสิทธิ์ดู', value: 'open', color: 'orange' }, { title: 'มีสิทธิ์แก้ไข', value: 'edit', color: 'green' }],
      form: this.$form.createForm(this, { name: 'dynamic_rule' })
    }
  },
  computed: {
    profile: get('account/profile'),
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  async created () {
    await this.myProfile()
    if (this.profile.issup) {
      clearAuthToken()
      this.deleteProfile()
      this.$router.push('/login')
    }
  },
  methods: {
    myProfile: call('account/myProfile'),
    deleteProfile: call('logout'),
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('Two passwords that you enter is inconsistent!')
      } else {
        callback()
      }
    },
    submit () {
      this.form.validateFields(async (err, value) => {
        if (!err) {
          const permissions = {
            member_mgm: this.permission_membermgm,
            report: this.permission_report,
            deposit: this.permission_deposit,
            api_key: this.permission_api
          }
          value.permissions = permissions
          try {
            const response = await this.axios.post(
              '/1.0/agent/subaccount',
              value,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            if (response.status === 200) {
              this.messageApi = response.data.message
              this.hasError = false
              this.form.resetFields()
              this.permission_membermgm = 'close'
              this.permission_report = 'close'
              this.permission_deposit = 'close'
              this.permission_api = 'close'
            }
          } catch (error) {
            this.messageApi = error.response.data.message
            this.hasError = true
          }
          if (this.hasError) {
            this.$message.error(this.messageApi)
          } else {
            this.$message.success(this.messageApi)
          }
        }
      })
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    }
  }
}
</script>
