<template>
  <div class="home">
    <a-row type="flex" justify="space-around" align="top">
      <a-col :lg="11" :md="11" :xs="24" :style="{ paddingTop: '15px', paddingLeft: '5px', paddingRight: '5px' }">
        <!-- <a-carousel arrows>
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 10px;zIndex: 1"
          >
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div v-for="banner in banners" :key="banner._id" >
            <h3>1</h3>
            <a-col :span="12">
            <img :src="banner.img" style="object-fit: contain; width: 500px; "/>
            </a-col>
          </div>
        </a-carousel> -->
        <a-card
          :title="$t('yourcredit')"
          :bordered="false"
          style="margin-top: 20px;"
        >
          <p style="text-align:left; font-weight: bold;">
            <span style="color: #7E8890;">
              {{ $t("credit") }}
            </span>
            <span style="float:right;">
              {{ numberWithCommas(balance) }}
            </span>
          </p>
        </a-card>
      </a-col>
      <a-col :lg="11" :md="11" :xs="24" :style="{ paddingTop: '15px', paddingLeft: '5px', paddingRight: '5px' }">
        <a-card
          :title="$t('balanceinfo')"
          :bordered="false"
          style="margin-top: 20px;color: #7E8890;"
        >
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{ $t("balanceday") }}
            </span>
            <span style="float:right;">
              {{ numberWithCommas(realBets_day) }}
            </span>
          </p>
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{ $t("winloseday") }}
            </span>
            <span style="float:right;">
              {{ numberWithCommas(bets_day) }}
            </span>
          </p>
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{ $t("balanceyesterday") }}
            </span>
            <span style="float:right;">
              {{ numberWithCommas(realBets_day_before) }}
            </span>
          </p>
          <p style="text-align:left; font-weight: bold;">
            <span>
              {{ $t("winloseyesterday") }}
            </span>
            <span style="float:right;">
              {{ numberWithCommas(bets_day_before) }}
            </span>
          </p>
        </a-card>
      </a-col>

      <!-- <a-col :span="11">
        <a-card
          :title="$t('yourmember')"
          :bordered="false"
          style="color: #7E8890;"
        >
          <div id="textbox">
            <p class="alignleft textbold">{{ $t("member") }}</p>
            <p class="aligncenter textbold">{{ $t("agent") }}</p>
          </div>
          <div id="textbox">
            <p class="alignleft textbold">{{ user }}</p>
            <p class="aligncenter textbold">{{ agents }}</p>
          </div>
        </a-card>
        <a-card
          :title="$t('newmember')"
          :bordered="false"
          style="margin-top: 20px;color: #7E8890;"
        >
          <div id="textbox">
            <p class="alignleft textbold">{{ $t("day") }}</p>
            <p class="aligncenter textbold">{{ $t("week") }}</p>
            <p class="alignright textbold">{{ $t("mount") }}</p>
          </div>
          <div id="textbox">
            <p class="alignleft textbold">{{ user_day }}</p>
            <p class="aligncenter textbold">{{ user_mount }}</p>
            <p class="alignright textbold">{{ user_weekly }}</p>
          </div>
        </a-card>
      </a-col> -->
    </a-row>
    <a-row type="flex" justify="space-around" align="top">
        <a-col :lg="11" :md="11" :xs="24" :style="{ paddingTop: '15px', paddingLeft: '5px', paddingRight: '5px' }">
          <a-card
            :title="$t('yourmember')"
            :bordered="false"
            style="color: #7E8890;"
          >
            <div id="textbox">
              <p class="alignleft textbold">{{ $t("member") }}</p>
              <p class="aligncenter textbold">{{ $t("agent") }}</p>
            </div>
            <div id="textbox">
              <p class="alignleft textbold">{{ numberWithCommas(user) }}</p>
              <p class="aligncenter textbold">{{ numberWithCommas(agents) }}</p>
            </div>
          </a-card>
        </a-col>
        <a-col :lg="11" :md="11" :xs="24" :style="{ paddingTop: '15px', paddingLeft: '5px', paddingRight: '5px' }">
          <a-card
            :title="$t('newmember')"
            :bordered="false"
            style="color: #7E8890;"
          >
            <div id="textbox">
              <p class="alignleft textbold">{{ $t("day") }}</p>
              <p class="aligncenter textbold">{{ $t("week") }}</p>
              <p class="alignright textbold">{{ $t("mount") }}</p>
            </div>
            <div id="textbox">
              <p class="alignleft textbold">{{ numberWithCommas(user_day) }}</p>
              <p class="aligncenter textbold">{{ numberWithCommas(user_mount) }}</p>
              <p class="alignright textbold">{{ numberWithCommas(user_weekly) }}</p>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-modal v-model="visible" title="ประกาศสำคัญ" width='80%' :footer="null">
        <div>
          <span style="font-size: 18px; font-weight: bolder; color: black;">เรียนท่านลูกค้าที่เคารพทุกท่าน</span>
          <br /><br />
          <span style="font-size: 18px; font-weight: bolder; color: red;">ประกาศสำคัญ:</span><br />
          <span style="font-size: 16px; color: black;">ขณะนี้ทางบริษัทได้เพิ่มเกมส์ใหม่ SBO</span><br />
          <span style="font-size: 16px; color: black;">ท่านสามารถเปิดเกมส์ให้ Downline ของท่านที่เมนู (เกมส์)</span>
          <br /><br />
          <span style="font-size: 16px; font-weight: bolder; color: black;">ลูกค้าทุกท่านโปรดตั้งค่าดังนี้เพื่อใช้งาน:</span><br />
          <span style="font-size: 16px; color: black;">- เปิดเกมส์ให้ Downline หรือ สายล่าง ของท่านที่เมนู </span><span style="font-size: 16px; color: red;">เกมส์ > เกมส์ตัวแทน</span><br />
          <span style="font-size: 16px; color: red;">* Member ของแต่ละสายจะเปิดอัตโนมัติ *</span><br />
          <span style="font-size: 16px; color: black;">- โปรดตั้งค่าโชว์เกมส์ของท่านที่ต้องที่เมนู </span><span style="font-size: 16px; color: red;">เกมส์ > เกมส์ทั้งหมด</span><br />
          <br /><br />
          <span style="font-size: 16px; color: black;">- ตั้งค่า % ถือหุ้นให้ Downline หรือ สายล่าง ของท่านที่เมนู </span><span style="font-size: 16px; color: red;">การจัดการสมาชิก > ค่าคอมมิชชั่นตัวแทน</span><br />
          <span style="font-size: 16px; color: red;">- โปรดตรวจสอบ % ถือหุ้นของ Downline ก่อนเปิดเกมส์</span>
          <br /><br />
          <span style="font-size: 16px; color: black;">** หากลูกค้าท่านใดไม่มีเกมส์ดังกล่าว ในระบบโปรดติดต่อ Upline หรือ สายบนของท่าน **</span>
          <br /><br />
          <div style="display: flex; flex-wrap: wrap;">
            <img src="https://cdn.areaslot.cc/img/products/s_img/sbo-s.png" style="width: 32%; height: 50%; padding: 1%" />
            <!-- <img src="https://cdn.areaslot.cc/img/products/s_img/pr-s.png" style="width: 32%; height: 50%; padding: 1%" />
            <img src="https://cdn.areaslot.cc/img/products/s_img/sa-s.png" style="width: 32%; height: 50%; padding: 1%" /> -->
          </div>
        </div>
      </a-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  data () {
    return {
      agents: 0,
      balance: 0,
      user: 0,
      user_day: 0,
      user_mount: 0,
      user_weekly: 0,
      realBets_day: 0,
      bets_day: 0,
      realBets_day_before: 0,
      bets_day_before: 0,
      banners: [],
      visible: true
    }
  },
  created () {
    this.fetchData()
    this.getbanners()
  },
  methods: {
    async fetchData () {
      try {
        const response = await axios.get('/1.0/agent/dashboard')
        if (response.status === 200) {
          this.agents = response.data.agents
          this.balance = response.data.balance
          this.user = response.data.user
          this.user_day = response.data.user_day
          this.user_weekly = response.data.user_weekly
          this.user_mount = response.data.user_mount
          this.realBets_day = response.data.realBets_day
            ? response.data.realBets_day
            : 0
          this.bets_day = response.data.bets_day ? response.data.bets_day : 0
          this.realBets_day_before = response.data.realBets_day_before
            ? response.data.realBets_day_before
            : 0
          this.bets_day_before = response.data.bets_day_before
            ? response.data.bets_day_before
            : 0
        }
      } catch (error) {}
    },
    async getbanners () {
      const { data } = await axios.get('/1.0/agent/banners')
      this.banners = data.banners
    },
    numberWithCommas (x) {
      x = Number(x)
      return x.toLocaleString()
    }
  }
}
</script>
<style>
.ant-card {
  border-radius: 10px !important;
}
.ant-card-head {
  border-radius: 10px 10px 0px 0px !important;
  /* background-color: red !important; */
}
</style>
<style scoped>
.textbold {
  font-weight: bold;
}
.ant-carousel >>> .slick-list {
  border-radius: 10px;
}
/* slick-slide slick-active slick-current */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  border-radius: 10px;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
  color: black;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

#textbox {
  display: flex;
  flex-flow: row wrap;
}

.alignleft {
  float: left;
  width: 33.33333%;
  text-align: center;
}
.aligncenter {
  float: left;
  width: 33.33333%;
  text-align: center;
}
.alignright {
  float: left;
  width: 33.33333%;
  text-align: center;
}

.ant-modal-root >>> .ant-modal-title {
  font-size: 30px !important;
  color: white !important;
  font-weight: bold !important;
}
.ant-modal-root >>> .ant-modal-header {
  background: rgb(2,0,36);
  background: linear-gradient(160deg, rgba(2,0,36,1) 21%, rgba(97,97,119,1) 50%, rgba(190,192,193,1) 100%);
}
</style>
