import Vue from 'vue'
import Vuex from 'vuex'
import pathify, { make } from 'vuex-pathify'
import axios from 'axios'

import member from './member'
import account from './account'

// import auth0 from 'auth0-js'
// properties
const state = {
  AUTH_TOKEN_KEY: 'authToken',
  users: [
    {
      grandCount: 0
    }
  ],
  detaildata: {
    grandCount: 0
  },
  posts: null,
  limitall: 1,
  limitdetail: 50,
  filterdata: {
    id: 'ptsdev',
    username: '',
    dateStart: '2021-07-03',
    dateEnd: '2021-07-10',
    currency: '',
    product: '',
    category: '',
    report: ['product', 'cetegory', 'account'],
    page: 1
  },
  userinfoactive: false,
  active: null,
  activeProp: {}
}

const getters = {
  startdate (state) {
    return new Date(state.filterdata.dateStart).toISOString()
  },
  enddate (state) {
    return new Date(state.filterdata.dateEnd).toISOString()
  },
  report (state) {
    return `[${state.filterdata.report}]`
  }
}

const actions = {
  ...make.actions(state),

  async getWinlose ({ getters }) {
    try {
      const { data } = await axios.get(
        `/1.0/amb/winlose?id=${state.filterdata.id}&currency=${state.filterdata.currency}&username=&startDate=${getters.startdate}&endDate=${getters.enddate}&product=${state.filterdata.product}&category=${state.filterdata.category}&reportBy=${getters.report}&page=${state.filterdata.page}&pageSize=${state.limitall}&timezone=7`
      )
      state.users = data.data.list
    } catch (error) {
      console.error(error)
    }
  },
  async getWinloseUser ({ getters }, active) {
    try {
      const { data } = await axios.get(
        `/1.0/amb/bet?id=${state.active.id}&currency=THB&product=${state.active.productId}&category=&startDate=${getters.startdate}&endDate=${getters.enddate}&page=${state.filterdata.page}&pageSize=30&timezone=7`
      )
      state.detaildata = data.data
      // if (state.filterdata.page > 1) {
      //   data.data.list.map(item => {
      //     state.users.list.push(item)
      //   })
      // }
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations = make.mutations(state)

// store
Vue.use(Vuex)
export default new Vuex.Store({
  // use the plugin
  plugins: [pathify.plugin],

  // properties
  state,
  getters,
  actions,
  mutations,
  modules: {
    member: member,
    account: account
  }
})
