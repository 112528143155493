<template>
  <div>
    <a-form :form="form">
      <a-row>
        <a-col :sm="24" :md="12">
          <span class="title_page">{{ $t('basicinfo') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('password')"
            >
              <a-input-password
                type="password"
                :placeholder="$t('password')"
                v-decorator="['password']"
              />
              <span style="color: red">{{$t('dontchang')}}</span>
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('nickname')"
            >
              <a-input
                :placeholder="$t('nickname')"
                v-decorator="['nickname']"
              />
            </a-form-item>

            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('phonenumber')"
            >
              <a-input
                :placeholder="$t('phonenumber')"
                v-decorator="['phone']"
              />
            </a-form-item>
          </div>
        </a-col>
        <a-col :sm="24" :md="12">
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="top">
        <a-col>
          <a-form-item>
            <a-button
              type="primary"
              @click="editUser"
            >
              {{ $t('edituser') }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formItemLayout, formTailLayout } from '../../utils/form_size'
import { sync } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

export default {
  props: ['datauser', 'visible', 'fetchMyDownline'],
  data () {
    return {
      formItemLayout,
      formTailLayout,
      hasErrors,
      form: this.$form.createForm(this, { name: 'edit_user' })
    }
  },
  computed: {
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  created () {
    this.setData()
  },
  watch: {
    datauser: function () {
      this.setData()
    }
  },
  methods: {
    async setData () {
      if (this.datauser[0].nickname !== null) {
        this.form.setFieldsValue({
          nickname: (await this.datauser[0].nickname) || ''
        })
      }
      if (this.datauser[0].phone !== null) {
        this.form.setFieldsValue({
          phone: (await this.datauser[0].phone) || ''
        })
      }
      this.form.setFieldsValue({
        password: ''
      })
    },
    async editUser (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          values.user_id = this.datauser[0]._id
          try {
            const response = await this.axios.put(
              '/1.0/user',
              values,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            if (response.status === 200) {
              this.messageApi = response.data.message
              this.hasError = false
              this.form.resetFields()
              this.visible()
              this.fetchMyDownline()
            }
          } catch (error) {
            this.messageApi = error.response.data.message
            this.hasError = true
          }
          if (this.hasError) {
            this.$message.error(this.messageApi)
          } else {
            this.$message.success(this.messageApi)
          }
        }
      })
    }
  }
}
</script>
