<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      size="small"
      :loading="loadingData"
      :pagination="{ defaultPageSize: itemsPerPage, total: totaluser, onChange: pageChange }"
      :scroll="{ x: 500 }"
    >
    </a-table>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '20%'
  },
  {
    title: 'Secret Key',
    dataIndex: 'secretkey',
    key: 'secretkey'
  },
  {
    title: 'Date Time',
    dataIndex: 'date',
    key: 'date',
    width: '20%'
  }
]

export default {
  data () {
    return {
      data: [],
      columns,
      loadingData: false,
      totaluser: 0,
      page: 1,
      itemsPerPage: 50
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      this.data = []
      this.loadingData = true
      try {
        const response = await axios.get('/1.0/agent/transfer', { params: { limit: this.itemsPerPage, skip: (this.page - 1) * this.itemsPerPage } })
        if (response.status === 200) {
          response.data.agents.map((items) => {
            this.data.push({
              key: items._id,
              username: items.username,
              secretkey: items.secret_key,
              date: moment(items.createdAt).format('DD-MM-YYYY HH:mm:ss')
            })
          })
          this.totaluser = response.data.total
          this.loadingData = false
        }
      } catch (error) {
        if (error.response.data.message === 'Unauthorized') {
          this.$router.push('/')
        }
        this.data = []
      }
    },
    pageChange (page) {
      this.page = page
      this.fetchData()
    }
  }
}
</script>
