<template>
  <div>
    <a-form :form="form">
      <a-row>
        <a-col :sm="24" :md="12">
          <span style="font-weight: bold;">{{ $t('basicinfo') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('password')"
            >
              <a-input-password
                v-decorator="['password']"
                type="password"
                :placeholder="$t('password')"
              />
              <span style="color: red">{{$t('dontchang')}}</span>
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('nickname')"
            >
              <a-input
                :placeholder="$t('nickname')"
                v-decorator="['nickname']"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              :label="$t('phonenumber')"
            >
              <a-input
                :placeholder="$t('phonenumber')"
                v-decorator="['phone']"
              />
            </a-form-item>
          </div>
          <span style="font-weight: bold;">{{ $t('creditsettings') }}</span>
          <br />
          <div style="margin-top: 20px;">
            <template v-if="profile._id === '6110d1ff47caf985cce5e470' || profile._id === '61ba2bb0f9b3631902088a0e' || profile._id === '61b9da42cfca2db54176aa21'">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('agenttype')"
              >
                <a-radio-group
                  a-radio-group
                  v-model="agentApiType"
                  @change="changeApi"
                >
                  <a-radio-button :value="true">
                    API
                  </a-radio-button>
                  <a-radio-button :value="false">
                    NON API
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </template>
            <template v-if="!agentApiType">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('wallettype')"
              >
                <a-radio-group
                  a-radio-group
                >
                  <a-radio-button checked>
                    Credit Line
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </template>
            <template v-else>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                :label="$t('wallettype')"
              >
                <a-radio-group
                  a-radio-group
                  v-model="wallettype"
                  @change="changewallettype"
                >
                  <a-radio-button value="seamless" disabled>
                    Seamless
                  </a-radio-button>
                  <a-radio-button value="transfer">
                    Transfer
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </template>
            <!-- <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="Callback URL"
            >
              <a-input
                v-decorator="['callback']"
                placeholder="Callback URL"
              />
            </a-form-item> -->
          </div>
        </a-col>
        <a-col :sm="24" :md="12">
          <span style="font-weight: bold;">{{ $t('etcsetting') }}</span> <br />
          <div style="margin-top: 20px;">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="Website URL"
            >
              <a-input
                v-decorator="['website']"
                placeholder="Website URL"
              />
            </a-form-item>
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="IP Whitelist"
            >
              <template v-for="(tag, index) in tags">
                <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                  <a-tag color="blue" :key="tag" :closable="index !== -1" @close="() => handleClose(tag)">
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag color="blue" v-else :key="tag" :closable="index !== -1" @close="() => handleClose(tag)">
                  {{ tag }}
                </a-tag>
              </template>
              <a-input
                v-if="inputVisible"
                ref="input"
                type="text"
                size="small"
                :style="{ width: '150px' }"
                :value="ipwhitelist"
                @change="handleInputChange"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
              />
              <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                <a-icon type="plus" /> New IP
              </a-tag>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="top">
        <a-col>
          <div>
            <a-form-item>
              <a-button
                type="primary"
                 @click="handleSubmit"
              >
                {{ $t('editagent') }}
              </a-button>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formItemLayout, formTailLayout } from '../../utils/form_size'
import { sync, get } from 'vuex-pathify'

function hasErrors (fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const agentApiType = true

export default {
  props: ['datauser', 'visible', 'fetchMyDownline'],
  data () {
    return {
      hasErrors,
      checkprefix: true,
      agentApiType,
      wallettype: 'transfer',
      checkNick: false,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: 'edit_agent' }),
      tags: [],
      inputVisible: false,
      ipwhitelist: ''
    }
  },
  computed: {
    profile: get('account/profile'),
    hasError: sync('member/hasError'),
    messageApi: sync('member/message')
  },
  created () {
    if (this.profile._id === '6110d1ff47caf985cce5e470' || this.profile._id === '61ba2bb0f9b3631902088a0e' || this.profile._id === '61b9da42cfca2db54176aa21') {
      this.agentApiType = true
    } else {
      this.agentApiType = false
    }
    this.setData()
  },
  watch: {
    datauser: function () {
      this.setData()
    }
  },
  methods: {
    async setData () {
      if (this.datauser[0].nickname !== null) {
        this.form.setFieldsValue({
          nickname: (await this.datauser[0].nickname) || ''
        })
      }
      if (this.datauser[0].phone !== null) {
        this.form.setFieldsValue({
          phone: (await this.datauser[0].phone) || ''
        })
      }
      if (this.datauser[0].website !== null) {
        this.form.setFieldsValue({
          website: (await this.datauser[0].website) || ''
        })
      }
      if (this.datauser[0].ipwhitelist !== null) {
        this.tags = this.datauser[0].ipwhitelist
      }
      this.form.setFieldsValue({
        password: ''
      })
    },
    handleClose (removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag)
      this.tags = tags
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },
    handleInputChange (e) {
      this.ipwhitelist = e.target.value
    },
    handleInputConfirm () {
      const ipwhitelist = this.ipwhitelist
      let tags = this.tags
      if (ipwhitelist && tags.indexOf(ipwhitelist) === -1) {
        tags = [...tags, ipwhitelist]
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        ipwhitelist: ''
      })
    },
    changeApi (e) {
      this.agentApiType = e.target.value
    },
    changewallettype (e) {
      this.wallettype = e.target.value
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          values.agent_id = this.datauser[0]._id
          values.ipwhitelist = this.tags
          values.isapi = this.agentApiType
          values.wallettype = this.wallettype
          try {
            const response = await this.axios.put(
              '/1.0/agent',
              values,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            if (response.status === 200) {
              this.messageApi = response.data.message
              this.hasError = false
              this.form.resetFields()
              this.visible()
              this.fetchMyDownline()
            }
          } catch (error) {
            this.messageApi = error.response.data.message
            this.hasError = true
          }
          if (this.hasError) {
            this.$message.error(this.messageApi)
          } else {
            this.$message.success(this.messageApi)
          }
        }
      })
    }
  }
}
</script>
<style>
 .text-credit-line {
   background-color: limegreen;
    text-align: center;
    color: white;
    font-weight: bold;
    border-radius: 10px;
 }
</style>
